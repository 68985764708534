import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";


@Component({
  selector: 'app-modal-universo-auditavel-importarcao',
  templateUrl: 'modal-universo-auditavel-importacao.component.html',
  styleUrls: ['modal-universo-auditavel-importacao.component.scss']
})
export class ModalUniversoAuditavelImportacaoComponent implements OnInit{
  private currentDialogRef: MatDialogRef<any>;

  unidadeGestoraId: number;
  tipoUniversoId: number;
  tipoUniverso: number;
  constructor(
    private dialogRef: MatDialogRef<ModalUniversoAuditavelImportacaoComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private router: Router,
  ) {


  }
  ngOnInit(): void {
   this.unidadeGestoraId = this.data.unidadeGestoraId;
   this.tipoUniversoId   = this.data.tipoUniversoId;
   this.tipoUniverso   = this.data.tipoUniverso;
  }
  public Sim(){
    this.dialogRef.close();
    this.router.navigate(['/importar-arquivo-universo-auditavel/novo'], { queryParams: { unidadeGestoraId: this.unidadeGestoraId, tipoUniversoId: this.tipoUniversoId } });
  }
  public Nao() {
    this.dialogRef.close();
  }
  public cancelar() {
    this.dialogRef.close();
  }

}
