import { EQuestionarioUGSituacao, EQuestionarioUGSituacaoDescricao } from "./questionario-ug-situacao.enum";

export  enum EUniversoAuditavelSituacao{
  EmElaboracao = 1,
  EmPreenchimento = 2,
  Revisao = 3,
  Pendente = 4,
  Avocado = 5,
  Expirado = 6,
  Finalizado = 7,
}
export const EUniversoAuditavelSituacaoDescricao = {
  [EUniversoAuditavelSituacao.EmElaboracao]: 'Em Elaboração',
  [EUniversoAuditavelSituacao.EmPreenchimento]: 'Em preenchimento',
  [EUniversoAuditavelSituacao.Pendente]: 'Pendente',
  [EUniversoAuditavelSituacao.Revisao]: 'Revisao',
  [EUniversoAuditavelSituacao.Avocado]: 'Avocado',
  [EUniversoAuditavelSituacao.Expirado]: 'Expirado',
  [EUniversoAuditavelSituacao.Finalizado]: 'Finalizado',
};
