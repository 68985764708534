import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { Component, OnInit } from "@angular/core";
import { FiltroModel } from "../../../../models/filtro.model";
import { AuthService } from "../../../../services/auth.service";
import { EntendendoEntidadeService } from "../../../../services/entendendo-entidade.service";
import { QuestionarioUGRespostaService } from "../../../../services/questionarioug-resposta.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "../../../../services/shared.service";
import { ToastrService } from "ngx-toastr";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import { IArquivoModel } from "../../../../models/arquivo.model";
import Swal from "sweetalert2";


@Component({
  selector: 'app-importar-arquivo-universo-auditavel',
  templateUrl: './importar-arquivo-universo-auditavel.component.html',
  styleUrls: ['./importar-arquivo-universo-auditavel.component.scss']
})
export class ImportarArquivoUniversoAuditavelComponent extends BaseFormComponent implements OnInit{
  public filtro = {} as FiltroModel;

  tipoUniversoId:number;
  unidadeGestoraId:number;
  searchTerm: string = '';
  nomeTipoUniverso: string = '';
  arquivo: File | null = null;
  arquivoUrl: string;
  nomeArquivo: string;
  errorMessage: string | null = null;
  botaoEncerrarHabilitado: boolean = false;
  constructor(
    private authService: AuthService,
    private tipoUniversoService: TipoUniversoService,
    private universoAuditavelService: UniversoAuditavelService,
    matDialog: MatDialog,
    router: Router,
    private sharedService: SharedService,
    route: ActivatedRoute,
    toastr: ToastrService,
    localeService: BsLocaleService
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {

    this.unidadeGestoraId = this.route.snapshot.queryParams['unidadeGestoraId']
    this.tipoUniversoId = this.route.snapshot.queryParams['tipoUniversoId']

    this.buscarTipoUniverso();
    this.downloadAnexo();
  }

 public buscarTipoUniverso(){

    if(this.tipoUniversoId !== null){
      this.tipoUniversoService.obterPorId(this.tipoUniversoId).then(res =>{
        if(res.sucesso){
          this.nomeTipoUniverso = res.dados.nome;
        }else{
          this.exibirMensagemAlerta('Tipo não encontrado!');
        }
      })
    }
  }


  onFileSelected(event: any): void {
    const file: File = event.target.files[0];
    if (file) {
      // Verifica se a extensão do arquivo é PDF
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension !== 'pdf') {
        // Emite um aviso caso a extensão não seja PDF
        this.errorMessage = 'Por favor, selecione um arquivo PDF!';
        this.arquivo = null; // Reseta o arquivo selecionado
      } else {
        this.arquivo = file;
        this.errorMessage = null;
      }
    }
  }
  public onBack() {
    this.submit = false;
    this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=3`);
  }

  enviarArquivo(): void {

    if (this.arquivo) {
      const arquivoModel: IArquivoModel = {
        id: 0,
        nomeArquivo: this.arquivo.name,
        entidadeArquivo: 1,
        arquivo: this.arquivo,
        url: ''
      };



      this.universoAuditavelService.enviarArquivo(this.unidadeGestoraId, this.tipoUniversoId , arquivoModel).then(
        (response) => {
          this.downloadAnexo();
          this.exibirMensagemSucesso('Arquivo importado com sucesso');
          // this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=1`);
        },
        (error) => {
          this.exibirMensagemErro(error.mensagem.descricao);
        }
      );
    }
  }

  search(): void {
    console.log('Searching for:', this.searchTerm);
    // Implementar lógica de pesquisa aqui
  }

  finalizarAvaliacao(): void {
    console.log('Avaliação finalizada');
    // Implementar lógica de finalização aqui
  }

  cancelar(): void {
    console.log('Operação cancelada');
    // Implementar lógica de cancelamento aqui
  }


  onEncerrarAvaliacao(){
    Swal.fire({
      title: 'Atenção',
      text: "Esta ação não poderá ser desfeita! Deseja continuar? ",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
    }).then((res) => {
      if (res.value) {
        this.universoAuditavelService.encerrarUniversoImportacaoArquivo(this.unidadeGestoraId, this.tipoUniversoId)
          .then((res) => {
            if (res.sucesso) {
              this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=3`);
            }
            else{
              this.exibirMensagemErro(res.mensagem.descricao);
            }
          })

      }
    })}

  downloadAnexo(): void {
    this.universoAuditavelService.DownloadArquivo(this.unidadeGestoraId, this.tipoUniversoId).then(res=> {
      if(res.sucesso){
        this.arquivoUrl = res.dados.url;
        this.nomeArquivo = res.dados.nomeArquivo;
        if (this.arquivoUrl) {
          this.botaoEncerrarHabilitado = true;  // Habilita o botão
        }
      }else{
        return;
      }
    }).catch(erro => {
      this.exibirMensagemErro("Arquivo não encontrado", erro.mensagem);
    });
  }
}
