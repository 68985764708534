import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { INiveisRiscoResidual } from 'src/app/models/niveis-risco-residual.model';
import { NiveisRiscoResidualService } from 'src/app/services/niveis-risco-residual.service';
import Swal from 'sweetalert2';
import { ModalRiscoResidualComponent } from './modal-risco-residual/modal-risco-residual.component';
import { FormulaRiscoResidualComponent } from './formula-risco-residual/formula-risco-residual.component';
import { FormulaRiscoResidualService } from 'src/app/services/formula-risco-residual.service';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";
import { NiveisRiscoInerenteService } from 'src/app/services/niveis-risco-inerente.service';
import { ControleService } from 'src/app/services/controle.service';

@Component({
  selector: 'app-criterios-avaliacao-form-niveis-risco-residual',
  templateUrl: './criterios-avaliacao-form-niveis-risco-residual.component.html',
  styleUrls: ['./criterios-avaliacao-form-niveis-risco-residual.component.scss']
})
export class CriteriosAvaliacaoFormNiveisRiscoResidualComponent implements OnInit {

  criteriosAvaliacaoRiscosId: any;
  formulaRiscoResidual: any;
  riscoInerente: any;
  avaliacaoControle: any;
  visualizar = false;
  dataSource = new MatTableDataSource<any>([]);
  selectedModel: any;

  listRisco: any[] = []

  displayedColumnsNiveisRisco: string[] = [
    "nome",
    "operador_1",
    "actions"
  ];

  searchNome: string;
  searchAtivo = true;
  semDados = true;
  constructor(
    private niveisRiscoService: NiveisRiscoResidualService,
    private formulaRiscoService: FormulaRiscoResidualService,
    private controleService: ControleService,
    private niveisRiscoServiceInerente: NiveisRiscoInerenteService,
    public matDialog: MatDialog,
  ) { }

  setCriteriosAvaliacaoRiscosId(criteriosAvaliacaoRiscosId: number) {
    this.criteriosAvaliacaoRiscosId = criteriosAvaliacaoRiscosId
    this.getRiscoResidualByCriterios()
    this.getFormulaRiscoResidual()

    this.getNiveisRiscoByCriterios()
    this.getControleByCriterios()
  }


  ngOnInit(): void { }

  salvarDados(e: any) { }

  abrirNiveisDeRisco(element?: INiveisRiscoResidual) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "1000px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: element ? element.id : 0,
      nome: element?.nome,
      operador: element?.operador,
      valor: element?.valor,
      operador_1: element?.operador_1,
      valor_1: element?.valor_1,
      ativo: element?.ativo,
      criteriosAvaliacaoRiscosId: this.criteriosAvaliacaoRiscosId,
      listData: this.listRisco
    };

    const modal = this.matDialog.open(
      ModalRiscoResidualComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getRiscoResidualByCriterios();
        this.getFormulaRiscoResidual();
        this.getControleByCriterios();
        this.getNiveisRiscoByCriterios();
      }
    });
  }

  excluir(e: any) {
    Swal.fire({
      title: "Excluir Risco Residual",
      text: "Tem certeza que deseja excluir esse Risco?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        this.niveisRiscoService.excluir(e.id).then(() => {
          this.getRiscoResidualByCriterios()
        })
        return;
      }
      return;
    });
  }

  getRiscoResidualByCriterios() {
    this.niveisRiscoService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      // this.dataSource = new MatTableDataSource(res.dados);
      // this.dataSource._updateChangeSubscription();
      this.listRisco = res.dados
      this.semDados = this.dataSource.filteredData.length === 0;
    })
  }

  getFormulaRiscoResidual() {
    this.formulaRiscoService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.formulaRiscoResidual = res?.dados[0]
    })
  }

  getRiscoControle(e: any) {
    let retorno = ""
    if (e.valor_1) {
      retorno = `${e.operador} ${e.valor} e ${e.operador_1} ${e.valor_1}`
    } else if (e.valor) {
      retorno = `${e.operador} ${e.valor}`
    }
    return retorno
  }

  abrirFormulaRisco() {
    debugger
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "1000px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: this.formulaRiscoResidual ? this.formulaRiscoResidual.id : 0,
      campo_1: this.formulaRiscoResidual?.campo_1,
      operador: this.formulaRiscoResidual?.operador,
      campo_2: this.formulaRiscoResidual?.campo_2,
      criteriosAvaliacaoRiscosId: this.criteriosAvaliacaoRiscosId,
      ativo: this.formulaRiscoResidual?.ativo,
      riscoInerente: this.riscoInerente,
      avaliacaoControle: this.avaliacaoControle
    };

    const modal = this.matDialog.open(
      FormulaRiscoResidualComponent,
      dialogConfig
    );

    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getFormulaRiscoResidual()
      }
    });
  }

  getControleByCriterios() {
    this.controleService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.avaliacaoControle = res?.dados
    })
  }

  getNiveisRiscoByCriterios() {
    this.niveisRiscoServiceInerente.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      this.riscoInerente = res?.dados
    })
  }


  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(this.listRisco, event.previousIndex, event.currentIndex);
    }

    this.listRisco.forEach((element, idx) => {
      element.ordem = (idx + 1).toString();
      this.niveisRiscoService.atualizar(element)
    })

  }

  exibirMensagemErro(mensagem: string, html: boolean = false) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: "error",
      html: mensagem,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }
}
