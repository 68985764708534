import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IControle } from 'src/app/models/controle.model';
import { ControleService } from 'src/app/services/controle.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-controle',
  templateUrl: './modal-controle.component.html',
  styleUrls: ['./modal-controle.component.scss']
})
export class ModalControleComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  model = {} as IControle;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;

  listData: any[] = []

  avaliacaoList = [
    { id: 1, nome: 'Inexistente' },
    { id: 2, nome: 'Fraco' },
    { id: 2, nome: 'Mediano' },
    { id: 4, nome: 'Satisfatório' },
    { id: 5, nome: 'Forte' },
  ]

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalControleComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    router: Router,
    private serviceControle: ControleService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data?.id;
    this.model.avaliacao = data?.avaliacao;
    this.model.valorDoRisco = data?.valorDoRisco;
    this.model.situacaoControle = data?.situacaoControle;
    this.model.nivelDaConfianca = data?.nivelDaConfianca;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;
    this.model.ativo = data?.ativo;
    this.listData = data?.listData

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Inserir"
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Atualizar"
    }
  }

  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  concluir() {

    let dados = this.listData.find(o => o.avaliacao == this.model.avaliacao)
    dados = this.listData.find(o => o.valorDoRisco == this.model.valorDoRisco)

    if (dados) {
      this.exibirMensagemErro('Nome do Controle ou valor já adicionado! Informe outro nome ou valor para prosseguir.')
      return
    }


    if (this.atualizando) {
      this.serviceControle.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.model.ordem = this.proximaOrdem(this.listData)
      this.serviceControle.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

  setNiveisConfianca() {
    if (this.model.avaliacao == 'Inexistente') {
      this.model.nivelDaConfianca = ''
      return
    }
    if (this.model.avaliacao == 'Fraco') {
      this.model.nivelDaConfianca = '20%'
      return
    }
    if (this.model.avaliacao == 'Mediano') {
      this.model.nivelDaConfianca = '40%'
      return
    }
    if (this.model.avaliacao == 'Satisfatório') {
      this.model.nivelDaConfianca = '60%'
      return
    }
    if (this.model.avaliacao == 'Forte') {
      this.model.nivelDaConfianca = '80%'
      return
    }
  }

  setValorRisco() {
    if (this.model.avaliacao == 'Inexistente') {
      this.model.valorDoRisco = '1,00'
      return
    }
    if (this.model.avaliacao == 'Fraco') {
      this.model.valorDoRisco = '0,8'
      return
    }
    if (this.model.avaliacao == 'Mediano') {
      this.model.valorDoRisco = '0,6'
      return
    }
    if (this.model.avaliacao == 'Satisfatório') {
      this.model.valorDoRisco = '0,4'
      return
    }
    if (this.model.avaliacao == 'Forte') {
      this.model.valorDoRisco = '0,2'
      return
    }
  }

  setValues() {
    this.setNiveisConfianca()
    this.setValorRisco()
  }

  proximaOrdem(array: any[]): number {
    if (array.length === 0) {
      return 1;
    }

    const maxOrdem = array.reduce((max, item) => {
      return item.ordem > max ? item.ordem : max;
    }, -Infinity);

    return maxOrdem + 1;
  }

  getRiscoControle(e: any) {
    let retorno = ""
    if (e.valor_1) {
      retorno = `${e.operador} ${e.valor} e ${e.operador_1} ${e.valor_1}`
    } else if (e.valor) {
      retorno = `${e.operador} ${e.valor}`
    }
    return retorno
  }

}
