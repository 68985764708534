import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { CriteriosAvaliacaoFormDadosComponent } from './criterios-avaliacao-form-dados/criterios-avaliacao-form-dados.component';
import { CriteriosAvaliacaoFormControlesComponent } from './criterios-avaliacao-form-controles/criterios-avaliacao-form-controles.component';
import { CriteriosAvaliacaoFormNiveisRiscoResidualComponent } from './criterios-avaliacao-form-niveis-risco-residual/criterios-avaliacao-form-niveis-risco-residual.component';
import { CriteriosAvaliacaoFormRiscoInerenteComponent } from './criterios-avaliacao-form-risco-inerente/criterios-avaliacao-form-risco-inerente.component';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';
import { ToastrService } from 'ngx-toastr';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ModalCancelarComponent } from 'src/app/shared/components/modal-cancelar/modal-cancelar-component';

@Component({
  selector: 'app-criterios-avaliacao-form',
  templateUrl: './criterios-avaliacao-form.component.html',
  styleUrls: ['./criterios-avaliacao-form.component.scss']
})
export class CriteriosAvaliacaoFormComponent extends BaseFormComponent implements OnInit {

  @ViewChild(CriteriosAvaliacaoFormDadosComponent, { static: false }) abaDados!: CriteriosAvaliacaoFormDadosComponent;
  @ViewChild(CriteriosAvaliacaoFormControlesComponent, { static: false }) abaDadosControle!: CriteriosAvaliacaoFormControlesComponent;
  @ViewChild(CriteriosAvaliacaoFormNiveisRiscoResidualComponent, { static: false }) abaRiscoResidual!: CriteriosAvaliacaoFormNiveisRiscoResidualComponent;
  @ViewChild(CriteriosAvaliacaoFormRiscoInerenteComponent, { static: false }) abaRiscoInerente!: CriteriosAvaliacaoFormRiscoInerenteComponent;

  form: any

  constructor(
    route: ActivatedRoute,
    toastr: ToastrService,
    router: Router,
    localeService: BsLocaleService,
    public matDialog: MatDialog
  ) {
    super(route, toastr, router, localeService, matDialog);
  }

  ngOnInit(): void {
    this.setTitulo()
  }

  dataUpdated(data: any) {
    this.setTitulo(data.nome)
  }

  setTitulo(titulo : string = "") {
    if (this.id) {
      this.novoRegistro = false;
    }
    if (this.visualizar) {
      this.titulo = `Visualizar - Critério de Avaliação de Risco ${titulo}`;
    } else if (this.novoRegistro) {
      this.titulo = `Novo Critério de Avaliação de Risco`;
    } else {
      this.titulo = `Editar - Critério de Avaliação de Risco ${titulo}`;
    }
  }

  ngAfterViewInit(): void {
    if (this.abaDados) {
      this.abaDados.getDataById(this.id)
      this.abaDados.visualizar = this.visualizar
      this.abaRiscoInerente.visualizar = this.visualizar
      this.abaDadosControle.visualizar = this.visualizar
      this.abaRiscoResidual.visualizar = this.visualizar
    }
  }

  mudarAba(ind: any) {

    if (!this.id || this.id == 0) {
      this.exibirMensagemErro("Necessário salvar o Critério de Avaliação");
      return;
    }

    this.aba = ind;
    if (this.aba == 1) {

    }
    switch (this.aba) {
      case 1:
        this.abaRiscoInerente.setCriteriosAvaliacaoRiscosId(this.id)
        break;
      case 2:
        this.abaDadosControle.setCriteriosAvaliacaoRiscosId(this.id)
        break;
      case 3:
        this.abaRiscoResidual.setCriteriosAvaliacaoRiscosId(this.id)
        break;
    }
  }

  onBack() {
    if (this.abaDados.form.dirty) {
      const dialogConfig = new MatDialogConfig();
      const modal = this.matDialog.open(ModalCancelarComponent, dialogConfig);
      modal.afterClosed().subscribe((resultado) => {
        if (resultado == true) {
          this.router.navigate(["/criteriosAvaliacao"]);
        }
      });

    } else {
      this.router.navigate(["/criteriosAvaliacao"]);
    }
  }

  salvarDados() {
    this.abaDados.salvarDados(this.aba);
    // switch (this.aba) {
    //   case 0:
    //     this.abaDados.salvarDados(this.aba);
    //     break;
    //   case 1:
    //     this.abaRiscoInerente.salvarDados(this.aba);
    //     break;
    //   case 2:
    //     this.abaDadosControle.salvarDados(this.aba);
    //     break;
    //   case 3:
    //     this.abaRiscoResidual.salvarDados(this.aba);
    //     break;
    // }
  }

  SalvarFechar() {
    this.abaDados.salvarDados(this.aba);
    this.onBack();
  }

}
