<div class="mat-elevation-z1">
    <mat-toolbar class="table-header">
        <mat-toolbar-row>
            <label class="mb-1" style="font-size: 16px">Controles</label>
            <span class="fill-remaining-space"></span>
            <button type="button" class="btn btn-success btn-sm mr-1" (click)="abrirModalControle()"
                [hidden]="visualizar">
                <i class="far fa-add"></i>
                Adicionar
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <!-- <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear class="mat-elevation-z8"
        #tabela1="matSort" [hidden]="semDados">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container matColumnDef="avaliacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Avaliação</th>
            <td mat-cell *matCellDef="let element">{{element.avaliacao}}</td>
        </ng-container>
        <ng-container matColumnDef="valorDoRisco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Risco de Controle - RC</th>
            <td mat-cell *matCellDef="let element">{{element.valorDoRisco}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="
                    let element;
                    let i = index
                " class="block text-right">
                <div class="btn-group">
                    <button [hidden]="visualizar" type="button" class="btn btn-primary btn-sm mr-1"
                        (click)="abrirModalControle(element)">
                        <i class="fas fa-pen"></i>
                    </button>
                    <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                        (click)="excluirControle(element)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" [ngClass]="'bg-light'">
        </tr>
    </table>
    <h5 [hidden]="!semDados" class="table-no-data">
        Nenhum tipo de trabalho
        cadastrado.
    </h5> -->

    <div>
        <table class="table table-condensed" cdkDropList (cdkDropListDropped)="onDrop($event)">
            <thead>
                <tr>
                    <th class="col-md-6">Avaliação</th>
                    <th class="col-md-2">Risco de Controle - RC</th>
                    <th class="col-md-3 text-right" style="padding-right: 35px;">Ações</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let element of listRisco; let i = index">
                    <tr cdkDrag [cdkDragData]="element" cdkDragLockAxis="y">
                        <td>{{element?.avaliacao}}</td>
                        <td>{{element?.valorDoRisco}}</td>
                        <td class="text-center" style="display: flex; justify-content: right;">
                            <div class="btn-group mr-1">
                                <div class="d-flex align-items-center mr-2">
                                    <button [hidden]="visualizar" type="button" class="btn btn-primary btn-sm mr-1"
                                        (click)="abrirModalControle(element)">
                                        <i class="fas fa-pen"></i>
                                    </button>
                                    <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                                        (click)="excluirControle(element)">
                                        <i class="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>