<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">{{textoModal}}</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <!-- <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-md-3 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador" class="mb-1">Campo 1</label>
                                <select id="inputOperador" #selectTipoTrabalho [(ngModel)]="model.campo_1"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of campo1List" [ngValue]="item.nome">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-2 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador" class="mb-1">Operador</label>
                                <select id="inputOperador" #selectTipoTrabalho [(ngModel)]="model.operador_1"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of operadorList" [ngValue]="item.id">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-3 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador_1" class="mb-1">Campo 2</label>
                                <select id="inputOperador_1" #selectTipoTrabalho [(ngModel)]="model.campo_2"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of campo2List" [ngValue]="item.nome">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-2 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador_1" class="mb-1">Operador</label>
                                <select id="inputOperador_1" #selectTipoTrabalho [(ngModel)]="model.operador_2"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of operadorList" [ngValue]="item.id">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                            <div class="form-group col-md-2 was-validated"
                                [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                                <label for="inputOperador_1" class="mb-1">Campo 3</label>
                                <select id="inputOperador_1" #selectTipoTrabalho [(ngModel)]="model.campo_3"
                                    class="form-control form-control-sm" required>
                                    <option *ngFor="let item of campo3List" [ngValue]="item.nome">
                                        {{ item.nome }}
                                    </option>
                                </select>
                                <div class="invalid-feedback">
                                    Campo obrigatório
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="container">
                    <div class="row">
                        <div *ngFor="let grupo of listData; let i = index" class="d-flex">
                            <div class="form-group" style="padding-right: 10px;">
                                <label class="mb-1">Campo {{i + 1}}</label>
                                <select class="form-control form-control-sm" [(ngModel)]="grupo.campo">
                                    <option *ngFor="let escala of escalasDataSource" [value]="escala.nome">{{
                                        escala.nome }}</option>
                                </select>
                            </div>
                            <div class="form-group" *ngIf="i < listData.length - 1"
                                style="padding-right: 10px;">
                                <label class="mb-1">Operador</label>
                                <select class="form-control form-control-sm" [(ngModel)]="grupo.operador">
                                    <option *ngFor="let op of operadorList" [value]="op.id">{{ op.nome }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <!-- <button type="submit">Salvar</button> -->
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()">
                    <i class="fas fa-check"></i> {{txtBtnConcluir}}
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>