<div class="content">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial
              </a>
            </li>
            <li class="breadcrumb-item">
              <a  href="javascript:void(0);"  (click)="retornoAbaUnidade()">Universo auditável</a>
            </li>
            <li class="breadcrumb-item active">{{ titulo }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="mat-elevation-z8">
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
              <!-- Coluna Subtipo -->

              <ng-container matColumnDef="subtipo">
                <th mat-header-cell *matHeaderCellDef> Subtipo </th>
                <td mat-cell style="vertical-align: middle" *matCellDef="let element; let i = index">
                  <ng-container >
                    {{ element.ordemExibicao }} - {{ element.tipoUniversoAuditavelItemNome }}
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="macroprocesso">
                <th mat-header-cell *matHeaderCellDef> Macroprocesso </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngFor="let itemMacro of element.itens">
                    <div class="macroprocesso-item">
                      {{ itemMacro.ordemExibicao }} - {{ itemMacro.tipoUniversoAuditavelItemNome }}
                    </div>
                  </ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="processos">
                <th mat-header-cell *matHeaderCellDef> Processo </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container *ngIf="element.itens?.length">
                    <ng-container *ngFor="let itemMacro of element.itens">
                      <div *ngIf="itemMacro.itens?.length" [id]="'processo-' + itemMacro.tipoUniversoAuditavelItemId" class="processos-container">
                        <div class="coluna" *ngFor="let coluna of getDivididosPorColunas(itemMacro)">
                          <div *ngFor="let processo of coluna" class="processo-item">
                            <div class="processo" *ngIf="itemMacro.tipoUniversoAuditavelItemId === processo.idItemPai">
                              {{ processo.ordemExibicao }} - {{ processo.tipoUniversoAuditavelItemNome }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </td>
              </ng-container>
              <ng-container matColumnDef="indicador" >
                <th mat-header-cell *matHeaderCellDef [attr.colspan]="colspanIdicador"> {{indicadorNome}} </th>
                <td mat-cell *matCellDef="let element">
                </td>
              </ng-container>

              <!-- Colunas Dinâmicas para Critérios -->
              <ng-container *ngFor="let criterio of criterios" [matColumnDef]="'criterio_' + criterio">
                <th mat-header-cell *matHeaderCellDef> {{ criterio }} </th>
                <td mat-cell *matCellDef="let element">
                  <div class="processo">
                    <div *ngFor="let resposta of getCriterioValue(element, criterio)" class="processo-item">
                      {{ resposta }}
                    </div>
                  </div>
                </td>
              </ng-container>


              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
        <i class="fas fa-times"></i> Cancelar
      </button>
    </div>

  </div>
</div>

