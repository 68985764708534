import { Injectable } from '@angular/core';
import { IBaseModel } from '../models/base.model';
import { LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from './base.service';
import { IModeloQuestionarioModel } from '../models/modelo-questionario.model';
import { FiltroModel } from '../models/filtro.model';
import { promise } from 'protractor';
import { PaginacaoModel } from '../models/paginacao.model';
import { filterBy } from '../core/helpers/filterby';
import { FiltroModeloQuestionario } from '../models/filtro-modelo-questionario';

@Injectable({
    providedIn: 'root',
  })
  export class ModeloQuestionarioService extends BaseService {

    constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
        super(httpClient, locationStrategy);
      }

      public async obter(filtro: FiltroModel): Promise<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>{
        let params = new HttpParams();
        params = params.append('pesquisa', filtro.pesquisa || '');

        if (filtro.ativo !== undefined) {
        params = params.append('ativo', filtro.ativo.toString());
        }

        return this.httpClient
                .get<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/modeloquestionario`), {})
                .toPromise()
      }

      public async obterPorId(id: number): Promise<IBaseModel<IModeloQuestionarioModel>>{
        return this.httpClient
                    .get<IBaseModel<IModeloQuestionarioModel>>(`${this.apiBaseUrl}/modeloquestionario/${id}`)
                    .toPromise();
      }

      public async inserir(data: IModeloQuestionarioModel): Promise<IBaseModel<IModeloQuestionarioModel>>{
        return this.httpClient
                    .post<IBaseModel<IModeloQuestionarioModel>>(`${this.apiBaseUrl}/modeloquestionario`, data)
                    .toPromise();
      }

      public async atualizar(data: IModeloQuestionarioModel): Promise<IBaseModel<IModeloQuestionarioModel>>{
        return this.httpClient
                    .put<IBaseModel<IModeloQuestionarioModel>>(`${this.apiBaseUrl}/modeloquestionario`, data)
                    .toPromise();
      }
    
      public async reativar(id: number): Promise<IBaseModel<boolean>>{
        return this.httpClient
                .post<IBaseModel<boolean>>(`${this.apiBaseUrl}/modeloquestionario/${id}/reativar`, {})
                .toPromise();
      }

      public async inativar(id: number): Promise<IBaseModel<boolean>>{
        return this.httpClient
                .post<IBaseModel<boolean>>(`${this.apiBaseUrl}/modeloquestionario/${id}/inativar`, {})
                .toPromise();
      }

      public async excluir(id: number): Promise<IBaseModel<boolean>>{
        return this.httpClient
                .delete<IBaseModel<boolean>>(`${this.apiBaseUrl}/modeloquestionario/${id}/excluir`, {})
                .toPromise();
      }

      public async obterModeloQuestionario(filtro: FiltroModeloQuestionario): Promise<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>{
        let params = new HttpParams();
        params = params.append('ativo', 'true');
        return this.httpClient
                .get<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/modeloquestionario/`), {})
                .toPromise()
      }

      public async obterModeloEntendendoEntidade(filtro: FiltroModel): Promise<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>{
        let params = new HttpParams();
        params = params.append('pesquisa', filtro.pesquisa || '');

        if (filtro.ativo !== undefined) {
        params = params.append('ativo', filtro.ativo.toString());
        }

        return this.httpClient
                .get<IBaseModel<PaginacaoModel<IModeloQuestionarioModel[]>>>(filterBy(filtro, `${this.apiBaseUrl}/modeloquestionario/modelos-entendendo-entidade`), {})
                .toPromise()
      }

      public async aprovarReprovarItem(id: number, idModeloQuestionario: number, aprovado: boolean): Promise<IBaseModel<IModeloQuestionarioModel>> {
        return this.httpClient
          .patch<IBaseModel<IModeloQuestionarioModel>>(`${this.apiBaseUrl}/modeloquestionario/${id}/aprovar-ou-reprovar-item/${idModeloQuestionario}/${aprovado}`, {})
          .toPromise();
      }
  }