import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import Inputmask from "inputmask";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { ToastrService } from "ngx-toastr";
import { IBaseModel } from "src/app/models/base.model";
import { ICargaCriterioPeaModel } from "src/app/models/carga-criterio-pea.model";
import { ICoordenacaoModel } from "src/app/models/coordenacao.model";
import { IFaixaClassificacaoModel } from "src/app/models/faixa-classificacao";
import { IPeaCoordenacaoCapacidadeModel } from "src/app/models/pea-coordenacao-capacidade.model";
import { IPeaCoordenacaoDiasUteisModel } from "src/app/models/pea-coordenacao-dias-uteis.model";
import { IPeaCoordenacaoModel } from "src/app/models/pea-coordenacao.model";
import { IPeaCriterioRespostaModel } from "src/app/models/pea-criterio-resposta.model";
import { IPeaUnidadeGestoraModel } from "src/app/models/pea-unidade-gestora.model";
import { IPeaModel } from "src/app/models/pea.model";
import { IUnidadeGestoraModel } from "src/app/models/unidade-gestora.model";
import { CoordenacaoService } from "src/app/services/coordenacao.service";
import { FaixaClassificacaoService } from "src/app/services/faixa-classificacao.service";
import { IndicadorService } from "src/app/services/indicador.service";
import { PeaService } from "src/app/services/pea.service";
import { PeriodoService } from "src/app/services/periodo.service";
import { TipoDispensaService } from "src/app/services/tipo-dispensa.service";
import { UnidadeGestoraService } from "src/app/services/unidade-gestora.service";
import { ModalConfirmarRejeitarComponent } from "src/app/shared/components/modal-confirmacao-rejeitar/modal-confirmacao-rejeitar.component";
import { BaseFormComponent } from "../../shared/components/base-form/base-form.component";
import { ModalCapacidadeComponent } from "./modal-capacidade/modal-capacidade.component";
import { ModalImportarArquivoCriterioComponent } from "./modal-importar-arquivo-criterio/modal-importar-arquivo-criterio.component";
import { ModalPreencherFormularioComponent } from "./modal-preencher-formulario/modal-preencher-formulario.component";
import { ModalSelecionarCoordenacoesComponent } from "./modal-selecionar-coordenacoes/modal-selecionar-coordenacoes.component";
import { ModalSelecionarUgComponent } from "./modal-selecionar-ug/modal-selecionar-ug.component";
import { ModalVisualizarRespostasComponent } from "./modal-visualizar-respostas/modal-visualizar-respostas.component";
import Swal from "sweetalert2";
import { INaturezaResponsabilidadeModel } from "src/app/models/natureza-responsabilidade";
import { ModalEnviarQuestionarioCriterioComponent } from "./modal-enviar-questionario-criterio/modal-enviar-questionario-criterio.component";
import { IEnviarQuestionarioModel } from "src/app/models/enviar-questionario.model";
import { ModalVisualizarQuestionarioCriterioComponent } from "./modal-visualizar-questionario-criterio/modal-visualizar-questionario-criterio.component";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { defaultDataAcessor } from "src/app/core/helpers/mat-sort-data-accessor";
import { IRelatoriaCapituloModel } from "src/app/models/relatoria-capitulo.model";
import { RankingDistribuicaoService } from "src/app/services/ranking-prioridade.service";
import { IPeaRankingPrioridadeModel } from "src/app/models/pea-ranking-prioridade.model";
import { Location } from "@angular/common";
import moment from "moment";
import { AuthService } from "src/app/services/auth.service";
import { IPeaDadosBasicosModel } from "src/app/models/pea-dados-basicos.model";
import { ModalDescricaoDiasUteisComponent } from "./modal-descricao-dias-uteis/modal-descricao-dias-uteis.component";
import { ModalDescricaoPEACComponent } from "./modal-descricao-peac/modal-descricao-peac.component";
import { EnumTipoFeriado } from "../../models/enum/tipo-feriado.enum";

@Component({
	selector: "app-pea-form",
	templateUrl: "./pea-form.component.html",
	styleUrls: ["./pea-form.component.scss"],
})
export class PeaFormComponent extends BaseFormComponent implements OnInit {
	public model: IPeaModel = {} as IPeaModel;
	public modelDadosBasicos: IPeaDadosBasicosModel =
		{} as IPeaDadosBasicosModel;

	public modelFaixaPadrao: IFaixaClassificacaoModel =
		{} as IFaixaClassificacaoModel;
	public modelFaixaPersonalizada: IFaixaClassificacaoModel =
		{} as IFaixaClassificacaoModel;
	public modelFaixa: IFaixaClassificacaoModel =
		{} as IFaixaClassificacaoModel;
	public ugDataSource = new MatTableDataSource<IPeaUnidadeGestoraModel>([]);
	public coordenacoesDataSource =
		new MatTableDataSource<IPeaCoordenacaoModel>([]);
	public criterioDataSource = new MatTableDataSource<ICargaCriterioPeaModel>(
		[]
	);
	public naturezaResponsabilidadeDataSource =
		new MatTableDataSource<INaturezaResponsabilidadeModel>([]);
	public capacidadeDataSource = new MatTableDataSource<any>([]);
	public distribuicaoDataSource = new MatTableDataSource<any>([]);
	public distribuicaoNaoContempladosDataSource = new MatTableDataSource<any>(
		[]
	);
	public distribuicaoPersonalizadoDataSource = new MatTableDataSource<any>(
		[]
	);
	public distribuicaoNaoContempladosPersonalizadoDataSource =
		new MatTableDataSource<any>([]);
	public ugSemDados = true;
	public criterioSemDados = true;
	public coordenacaoSemDados = true;
	public capacidadeSemDados = true;
	public distribuicaoSemDados = true;
	public distribuicaoNaoContempladosSemDados = true;
	public distribuicaoSemDadosPersonalizado = true;
	public distribuicaoNaoContempladosSemDadosPersonalizado = true;
	public salvarDadosPendente = false;
	public salvarDistribuicaoPendente = false;
	public aba = 0;
	public situacao = 0;
	public habilitarHomologarRejeitar = false;
	public habilitarUltimoNivelAprovacao = false;
	public peaHomologado = false;
	public peaQuestionario = false;
	public columnsDistribuicao = [];
	public columnsDistribuicaoPersonalizado = [];

	public rankingPrioridadeDataSource =
		new MatTableDataSource<IPeaRankingPrioridadeModel>([]);
	public rankingPrioridadeEmpresasPublicasDataSource =
		new MatTableDataSource<IPeaRankingPrioridadeModel>([]);
	public semDadosRanking = false;
	public semDadosRankingEmpresaPublica = false;

	public columnsCapacidade = [
		"coordenacao",
		"diasDisponiveis",
		"equipesDisponiveis",
		"diasPorAuditoria",
		"auditoriasPorEquipe",
		"capacidadeTotalCoordenacao",
		"actions",
	];

	public relatoriaDados: IRelatoriaCapituloModel[] = null;

	public visualizarRelatoria = false;
	public visualizarBotaoFormulario = false;
	public hoje = moment();
	pisoAlto: string;
	tetoAlto: string;
	pisoMedio: string;
	tetoMedio: string;
	pisoBaixo: string;
	tetoBaixo: string;

	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	@ViewChild(MatSort, { static: true }) sort: MatSort;

	public form = new FormGroup({
		nome: new FormControl("", Validators.required),
		periodoId: new FormControl(null, Validators.required),
		indicadorId: new FormControl(null, Validators.required),
		subcapitulo: new FormControl(""),
	});

	constructor(
		route: ActivatedRoute,
		toastr: ToastrService,
		router: Router,
		localeService: BsLocaleService,
		private activatedRoute: ActivatedRoute,
		private peaService: PeaService,
		private periodoService: PeriodoService,
		private indicadorService: IndicadorService,
		private coordenacaoService: CoordenacaoService,
		private tipoDispensaService: TipoDispensaService,
		private ugService: UnidadeGestoraService,
		private faixaClassificacaoService: FaixaClassificacaoService,
		public matDialog: MatDialog,
		public rankingService: RankingDistribuicaoService,
		private _location: Location,
		private authService: AuthService
	) {
		super(route, toastr, router, localeService, matDialog);
		if (this.novoRegistro) {
			this.titulo = "Novo PEAC";
		}
		if (this.visualizar === true) {
			this.visualizarRelatoria = true;
		}
	}

	async ngOnInit() {
		Inputmask().mask(document.querySelectorAll("input"));
		const numeroAba = this.activatedRoute.snapshot.queryParamMap.get("aba");
		if (numeroAba) {
			await this.obterDadosBasicos();
			return;
		}

		await this.obterDadosBasicos();
	}

	ngAfterViewInit() {
		const numeroAba = this.activatedRoute.snapshot.queryParamMap.get("aba");
		if (numeroAba) {
			this.mudarAba(Number(numeroAba));
		}
	}

	public receberFaixaClassificacao(
		faixaClassificacao: IFaixaClassificacaoModel
	) {
		this.modelFaixa = faixaClassificacao;
	}

	public async obterDadosBasicos() {
		try {
			if (!this.novoRegistro) {
				this.peaService.obterDadosBasicos(this.id).then((res) => {
					this.showPendencias(res?.pendencias);

					if (res.sucesso) {
						this.modelDadosBasicos = res.dados;
						if (this.modelDadosBasicos.situacao.codigo !== 1) {
							this.visualizar = true;
						}

						if (this.novoRegistro) {
							this.titulo = "Novo PEAC";
						} else if (this.visualizar) {
							this.titulo =
								"Visualizar PEAC - " +
								this.modelDadosBasicos.nome;
						} else {
							this.titulo =
								"Editar PEAC - " + this.modelDadosBasicos.nome;
						}
						this.form.patchValue({
							periodoId: this.modelDadosBasicos.periodoId,
							indicadorId: this.modelDadosBasicos.indicadorId,
						});
						this.situacao =
							this.modelDadosBasicos?.situacao?.codigo;
						this.peaHomologado =
							this.modelDadosBasicos.situacao.codigo === 4;

						this.atualizarListaUgs(
							this.modelDadosBasicos.peaUnidadeGestora
						);
						this.coordenacoesDataSource =
							new MatTableDataSource<IPeaCoordenacaoModel>(
								this.modelDadosBasicos.peaCoordenacao
							);
						this.coordenacoesDataSource._updateChangeSubscription();
						this.coordenacaoSemDados =
							this.coordenacoesDataSource.filteredData.length ===
							0;

						this.form.patchValue(this.modelDadosBasicos);
					} else {
						this.submit = false;
						this.exibirMensagemErro(res.mensagem.descricao);
						this.router.navigate(["/pea"]);
						return;
					}
				});
			}
		} catch (err) {
			this.submit = false;
			this.exibirMensagemErro(err.mensagem?.descricao);
			this.router.navigate(["/pea"]);
		}
	}

	public async obterAbaCargaCriterios() {
		this.obterCargaCriterios();
	}

	public async obterAbaCapacidadeTrabalho() {
		this.obterCapacidadeTrabalho(this.id);
	}

	public async obterAbaRanking() {
		this.obterRankingPrioridade();
	}

	public async obterAbaDistribuicao() {
		await this.obterPainelDistribuicao(this.id);
	}

	public async obterDados() {
		try {
			if (!this.novoRegistro) {
				this.peaService.obterPorId(this.id).then((res) => {
					this.showPendencias(res?.pendencias);

					if (res.sucesso) {
						this.model = res.dados;
						if (this.model.situacao.codigo !== 1) {
							this.visualizar = true;
						}
						if (
							this.model.situacao.codigo !== 1 &&
							this.model.situacao.codigo !== 2
						) {
							this.visualizarRelatoria = true;
						}

						if (this.novoRegistro) {
							this.titulo = "Novo PEAC";
						} else if (this.visualizar) {
							this.titulo =
								"Visualizar PEAC - " + this.model.nome;
						} else {
							this.titulo = "Editar PEAC - " + this.model.nome;
						}
						this.form.patchValue({
							periodoId: this.model.periodoId,
							indicadorId: this.model.indicadorId,
						});
						this.situacao = this.model?.situacao?.codigo;
						this.habilitarHomologarRejeitar =
							this.model.situacao.codigo === 3;
						this.habilitarUltimoNivelAprovacao =
							this.model.situacao.codigo === 3;
						this.peaHomologado = this.model.situacao.codigo === 4;

						this.atualizarListaUgs(this.model.peaUnidadeGestora);
						this.coordenacoesDataSource =
							new MatTableDataSource<IPeaCoordenacaoModel>(
								this.model.peaCoordenacao
							);
						this.coordenacoesDataSource._updateChangeSubscription();
						this.coordenacaoSemDados =
							this.coordenacoesDataSource.filteredData.length ===
							0;

						this.obterCargaCriterios();
						this.obterCapacidadeTrabalho(this.id);

						this.obterPainelDistribuicao(this.id);

						this.faixaClassificacaoService.obter().then((res) => {
							this.pisoAlto = res.dados.pisoAlto
								.toFixed(4)
								.toString()
								.replace(".", ",");
							this.tetoAlto = res.dados.tetoAlto
								.toFixed(4)
								.toString()
								.replace(".", ",");
							this.pisoMedio = res.dados.pisoMedio
								.toFixed(4)
								.toString()
								.replace(".", ",");
							this.tetoMedio = res.dados.tetoMedio
								.toFixed(4)
								.toString()
								.replace(".", ",");
							this.pisoBaixo = res.dados.pisoBaixo
								.toFixed(4)
								.toString()
								.replace(".", ",");
							this.tetoBaixo = res.dados.tetoBaixo
								.toFixed(4)
								.toString()
								.replace(".", ",");

							this.modelFaixaPadrao = res.dados;

							if (!this.model.classificacaoPersonalizada) {
								//this.modelFaixa = this.modelFaixaPadrao;
								this.modelFaixa = {
									pisoBaixo: this.modelFaixaPadrao?.pisoBaixo,
									tetoBaixo: this.modelFaixaPadrao?.tetoBaixo,
									pisoMedio: this.modelFaixaPadrao?.pisoMedio,
									tetoMedio: this.modelFaixaPadrao?.tetoMedio,
									pisoAlto: this.modelFaixaPadrao?.pisoAlto,
									tetoAlto: this.modelFaixaPadrao?.tetoAlto,
								} as IFaixaClassificacaoModel;
								this.model.classificacaoPisoBaixo =
									this.modelFaixaPadrao?.pisoBaixo ?? 0;
								this.model.classificacaoTetoBaixo =
									this.modelFaixaPadrao?.tetoBaixo ?? 0;
								this.model.classificacaoPisoMedio =
									this.modelFaixaPadrao?.pisoMedio ?? 0;
								this.model.classificacaoTetoMedio =
									this.modelFaixaPadrao?.tetoMedio ?? 0;
								this.model.classificacaoPisoAlto =
									this.modelFaixaPadrao?.pisoAlto ?? 0;
								this.model.classificacaoTetoAlto =
									this.modelFaixaPadrao?.tetoAlto ?? 0;
							} else {
								this.modelFaixa = {
									pisoBaixo:
										this.model.classificacaoPisoBaixo,
									tetoBaixo:
										this.model.classificacaoTetoBaixo,
									pisoMedio:
										this.model.classificacaoPisoMedio,
									tetoMedio:
										this.model.classificacaoTetoMedio,
									pisoAlto: this.model.classificacaoPisoAlto,
									tetoAlto: this.model.classificacaoTetoAlto,
								} as IFaixaClassificacaoModel;
							}
						});
						this.form.patchValue(this.model);
					} else {
						this.submit = false;
						this.exibirMensagemErro(res.mensagem.descricao);
						this.router.navigate(["/pea"]);
						return;
					}
				});
			}
		} catch (err) {
			this.submit = false;
			this.exibirMensagemErro(err.mensagem?.descricao);
			this.router.navigate(["/pea"]);
		}
	}

	public atualizarListaUgs(peaUnidadeGestora: IPeaUnidadeGestoraModel[]) {
		const listaUgs = peaUnidadeGestora.map((_) => ({
			..._,
			nome: `${_.unidadeGestora.sigla} - ${_.unidadeGestora.nome}`,
		}));
		this.ugDataSource.paginator = this.paginator;
		this.ugDataSource.sort = this.sort;
		this.ugDataSource.filterPredicate = (
			data: { nome: string },
			filterValue: string
		) => {
			return (
				(data.nome || "").trim().toLowerCase().indexOf(filterValue) !==
				-1
			);
		};
		listaUgs.sort((a, b) => a["nome"].localeCompare(b["nome"]));
		this.ugDataSource = new MatTableDataSource<IPeaUnidadeGestoraModel>(
			listaUgs
		);
		this.ugDataSource.sortingDataAccessor = defaultDataAcessor;
		this.ugDataSource._updateChangeSubscription();
		this.ugSemDados = this.ugDataSource.filteredData.length === 0;
	}

	public cancelar() {
		this._location.back();
	}

	public obterCargaCriterios() {
		this.peaService
			.obterCargaCriteriosPorId(this.id)
			.then((resCriterios) => {
				const criterios = resCriterios.dados.map(
					(c) =>
						({
							...c,
							questionariosEnviados:
								c.peaCriterioQuestionario.length > 0,
						} as ICargaCriterioPeaModel)
				);
				this.peaQuestionario =
					criterios.filter(
						(_) => _.criterio.tipoEntrada.nome === "Questionario"
					).length > 0;
				criterios.forEach((element) => {
					element.peaCriterioQuestionario.forEach((item) => {
						this.visualizarBotaoFormulario =
							this.hoje.diff(item.dataLimite) >= 0 &&
							item.status == "Expirado";
						if (this.visualizarBotaoFormulario) {
							const texto =
								"O questionário: " +
								item.criterio.nome +
								" - não foi respondido pela unidade gestora - " +
								item.unidadeGestora.nome +
								" (prazo expirado).";
							this.exibirMensagemAlerta(texto);
						}
					});
				});
				this.criterioDataSource =
					new MatTableDataSource<ICargaCriterioPeaModel>(criterios);
				this.criterioDataSource._updateChangeSubscription();
				this.criterioSemDados =
					this.criterioDataSource.filteredData.length === 0;
			});
	}

	public obterCapacidadeTrabalho(peaId: number): Promise<void> {
		return this.peaService
			.obterCapacidadeTrabalhoPorId(peaId)
			.then((resCapacidade) => {
				this.showPendencias(resCapacidade?.pendencias);

				let dadosPreparados = [];
				const anos = [
					...new Set(resCapacidade.dados?.map((d) => d.ano)),
				];

				for (const ano of anos) {
					// Header
					dadosPreparados.push({
						grupo: true,
						ano,
					});

					// Dados
					dadosPreparados = dadosPreparados.concat(
						resCapacidade.dados.filter((c) => c.ano === ano)
					);
					// Footer
					dadosPreparados.push({
						subTotal: true,
						capacidadeTotalAno: resCapacidade.dados
							.filter((c) => c.ano === ano)
							.map((c) => c.capacidadeTotalCoordenacao)
							.reduce((sum, record) => sum + record),
					});
				}
				this.capacidadeDataSource = new MatTableDataSource<any>(
					dadosPreparados
				);
				this.capacidadeDataSource._updateChangeSubscription();
				this.capacidadeSemDados =
					this.capacidadeDataSource.filteredData.length === 0;
			})
			.catch((err) => {
				this.exibirMensagemErro(
					"Houve uma falha ao buscar os dados de Capacidade de Trabalho para esse PEAC."
				);
			});
	}

	public async obterPainelDistribuicao(peaId: number): Promise<void> {
		await this.peaService
			.obterPainelDistribuicaoPorPeaId(peaId)
			.then((resPainel) => {
				if (!resPainel.dados) {
					return;
				}

				this.distribuicaoDataSource = new MatTableDataSource<any>();
				this.distribuicaoDataSource._updateChangeSubscription();
				this.distribuicaoNaoContempladosDataSource =
					new MatTableDataSource<any>();
				this.distribuicaoNaoContempladosDataSource._updateChangeSubscription();
				this.distribuicaoPersonalizadoDataSource =
					new MatTableDataSource<any>();
				this.distribuicaoPersonalizadoDataSource._updateChangeSubscription();
				this.distribuicaoNaoContempladosPersonalizadoDataSource =
					new MatTableDataSource<any>();
				this.distribuicaoNaoContempladosPersonalizadoDataSource._updateChangeSubscription();

				this.model.classificacaoPersonalizada =
					resPainel.dados.classificacaoPersonalizada;

				this.pisoAlto =
					resPainel.dados.faixaClassificacaoPadrao.pisoAlto
						.toFixed(4)
						.toString()
						.replace(".", ",");
				this.tetoAlto =
					resPainel.dados.faixaClassificacaoPadrao.tetoAlto
						.toFixed(4)
						.toString()
						.replace(".", ",");
				this.pisoMedio =
					resPainel.dados.faixaClassificacaoPadrao.pisoMedio
						.toFixed(4)
						.toString()
						.replace(".", ",");
				this.tetoMedio =
					resPainel.dados.faixaClassificacaoPadrao.tetoMedio
						.toFixed(4)
						.toString()
						.replace(".", ",");
				this.pisoBaixo =
					resPainel.dados.faixaClassificacaoPadrao.pisoBaixo
						.toFixed(4)
						.toString()
						.replace(".", ",");
				this.tetoBaixo =
					resPainel.dados.faixaClassificacaoPadrao.tetoBaixo
						.toFixed(4)
						.toString()
						.replace(".", ",");

				this.modelFaixaPadrao =
					resPainel.dados.faixaClassificacaoPadrao;
				this.modelFaixaPersonalizada =
					resPainel.dados.faixaClassificacaoPersonalizada;

				if (!this.model.classificacaoPersonalizada) {
					this.modelFaixa = this.modelFaixaPadrao;
					this.model.classificacaoPisoBaixo =
						this.modelFaixaPadrao?.pisoBaixo ?? 0;
					this.model.classificacaoTetoBaixo =
						this.modelFaixaPadrao?.tetoBaixo ?? 0;
					this.model.classificacaoPisoMedio =
						this.modelFaixaPadrao?.pisoMedio ?? 0;
					this.model.classificacaoTetoMedio =
						this.modelFaixaPadrao?.tetoMedio ?? 0;
					this.model.classificacaoPisoAlto =
						this.modelFaixaPadrao?.pisoAlto ?? 0;
					this.model.classificacaoTetoAlto =
						this.modelFaixaPadrao?.tetoAlto ?? 0;
				} else {
					this.modelFaixa = this.modelFaixaPersonalizada;
					this.model.classificacaoPisoBaixo =
						this.modelFaixaPersonalizada?.pisoBaixo ?? 0;
					this.model.classificacaoTetoBaixo =
						this.modelFaixaPersonalizada?.tetoBaixo ?? 0;
					this.model.classificacaoPisoMedio =
						this.modelFaixaPersonalizada?.pisoMedio ?? 0;
					this.model.classificacaoTetoMedio =
						this.modelFaixaPersonalizada?.tetoMedio ?? 0;
					this.model.classificacaoPisoAlto =
						this.modelFaixaPersonalizada?.pisoAlto ?? 0;
					this.model.classificacaoTetoAlto =
						this.modelFaixaPersonalizada?.tetoAlto ?? 0;
				}

				// Padrão
				// Contempladas
				const tabela = [];
				this.columnsDistribuicao = [
					...new Set(
						resPainel.dados.painelDistribuicaoPadrao?.painel.map(
							(d) => d.ano.toString()
						)
					),
				];

				for (const item of resPainel.dados.painelDistribuicaoPadrao?.painel.filter(
					(d) => d.contemplada
				)) {
					if (
						tabela.length === 0 ||
						tabela.findIndex((r) => !r[item.ano.toString()]) < 0
					) {
						const row = {};
						row[item.ano.toString()] = item.unidadeGestoraSigla;
						row[item.ano.toString() + "_class"] =
							item.blocoPainel.nome;
						row[item.ano.toString() + "_indice"] =
							(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
						tabela.push(row);
					} else {
						const row =
							tabela[
								tabela.findIndex((r) => !r[item.ano.toString()])
							];
						row[item.ano.toString()] = item.unidadeGestoraSigla;
						row[item.ano.toString() + "_class"] =
							item.blocoPainel.nome;
						row[item.ano.toString() + "_indice"] =
							(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
					}
				}

				this.distribuicaoDataSource = new MatTableDataSource<any>(
					tabela
				);
				this.distribuicaoDataSource._updateChangeSubscription();
				this.distribuicaoSemDados =
					this.distribuicaoDataSource.filteredData.length === 0;

				// Não Contempladas
				const tabelaNaoContemplados = [];

				for (const item of resPainel.dados.painelDistribuicaoPadrao?.painel.filter(
					(d) => !d.contemplada
				)) {
					if (
						tabelaNaoContemplados.length === 0 ||
						tabelaNaoContemplados.findIndex(
							(r) => !r[item.ano.toString()]
						) < 0
					) {
						const row = {};
						row[item.ano.toString()] = item.unidadeGestoraSigla;
						row[item.ano.toString() + "_indice"] =
							(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
						tabelaNaoContemplados.push(row);
					} else {
						const row =
							tabelaNaoContemplados[
								tabelaNaoContemplados.findIndex(
									(r) => !r[item.ano.toString()]
								)
							];
						row[item.ano.toString()] = item.unidadeGestoraSigla;
						row[item.ano.toString() + "_indice"] =
							(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
					}
				}

				this.distribuicaoNaoContempladosDataSource =
					new MatTableDataSource<any>(tabelaNaoContemplados);
				this.distribuicaoNaoContempladosDataSource._updateChangeSubscription();
				this.distribuicaoNaoContempladosSemDados =
					this.distribuicaoNaoContempladosDataSource.filteredData
						.length === 0;

				// Personalizada
				// Contempladas
				if (resPainel.dados.painelDistribuicaoPersonalizado) {
					const tabelaPersonalizada = [];
					this.columnsDistribuicaoPersonalizado = [
						...new Set(
							resPainel.dados.painelDistribuicaoPersonalizado?.painel.map(
								(d) => d.ano.toString()
							)
						),
					];

					for (const item of resPainel.dados.painelDistribuicaoPersonalizado?.painel.filter(
						(d) => d.contemplada
					)) {
						if (
							tabelaPersonalizada.length === 0 ||
							tabelaPersonalizada.findIndex(
								(r) => !r[item.ano.toString()]
							) < 0
						) {
							const row = {};
							row[item.ano.toString()] = item.unidadeGestoraSigla;
							row[item.ano.toString() + "_class"] =
								item.blocoPainel.nome;
							row[item.ano.toString() + "_indice"] =
								(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
							tabelaPersonalizada.push(row);
						} else {
							const row =
								tabelaPersonalizada[
									tabelaPersonalizada.findIndex(
										(r) => !r[item.ano.toString()]
									)
								];
							row[item.ano.toString()] = item.unidadeGestoraSigla;
							row[item.ano.toString() + "_class"] =
								item.blocoPainel.nome;
							row[item.ano.toString() + "_indice"] =
								(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
						}
					}

					this.distribuicaoPersonalizadoDataSource =
						new MatTableDataSource<any>(tabelaPersonalizada);
					this.distribuicaoPersonalizadoDataSource._updateChangeSubscription();
				} else {
					this.distribuicaoPersonalizadoDataSource =
						new MatTableDataSource<any>([]);
					this.distribuicaoPersonalizadoDataSource._updateChangeSubscription();
				}

				this.distribuicaoSemDadosPersonalizado =
					this.distribuicaoPersonalizadoDataSource.filteredData
						.length === 0;

				// Não Contempladas
				if (resPainel.dados.painelDistribuicaoPersonalizado) {
					const tabelaNaoContempladosPersonalizada = [];

					for (const item of resPainel.dados.painelDistribuicaoPersonalizado?.painel.filter(
						(d) => !d.contemplada
					)) {
						if (
							tabelaNaoContempladosPersonalizada.length === 0 ||
							tabelaNaoContempladosPersonalizada.findIndex(
								(r) => !r[item.ano.toString()]
							) < 0
						) {
							const row = {};
							row[item.ano.toString()] = item.unidadeGestoraSigla;
							row[item.ano.toString() + "_indice"] =
								(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
							tabelaNaoContempladosPersonalizada.push(row);
						} else {
							const row =
								tabelaNaoContempladosPersonalizada[
									tabelaNaoContempladosPersonalizada.findIndex(
										(r) => !r[item.ano.toString()]
									)
								];
							row[item.ano.toString()] = item.unidadeGestoraSigla;
							row[item.ano.toString() + "_indice"] =
								(item.indicePriorizacao ?? 0)?.toFixed(4) ?? 0;
						}
					}

					this.distribuicaoNaoContempladosPersonalizadoDataSource =
						new MatTableDataSource<any>(
							tabelaNaoContempladosPersonalizada
						);
					this.distribuicaoNaoContempladosPersonalizadoDataSource._updateChangeSubscription();
					this.distribuicaoNaoContempladosSemDadosPersonalizado =
						this.distribuicaoNaoContempladosPersonalizadoDataSource
							.filteredData.length === 0;
				} else {
					this.distribuicaoPersonalizadoDataSource =
						new MatTableDataSource<any>([]);
					this.distribuicaoPersonalizadoDataSource._updateChangeSubscription();
				}
			})
			.catch((err) => {
				this.exibirMensagemErro(
					"Houve uma falha ao buscar os dados desse PEAC."
				);
			});
	}

	public agrupadorCapacidade(index: number, item: any): boolean {
		return item.grupo;
	}

	public subTotalCapacidade(index: number, item: any): boolean {
		return item.subTotal;
	}

	public async descricaoDiasUteis(
		capacidade: IPeaCoordenacaoDiasUteisModel,
		visualizar?: boolean
	) {
		try {
			const feriadosPorTipo = capacidade.extras.feriadosPorTipo as Record<
				EnumTipoFeriado,
				number
			>;

			const coordenacao = (
				await this.coordenacaoService.obterCapacidadePorAno(
					capacidade.coordenacaoId,
					capacidade.ano
				)
			).dados;

			const auditoriasEspeciais = capacidade.extras
				.auditoriasEspeciaisGet || { id: "", valor: "" };

			const feriadoEnumToKeyMap = {
				[EnumTipoFeriado.Nacional]: "nacional",
				[EnumTipoFeriado.Estadual]: "estadual",
				[EnumTipoFeriado.Municipal]: "municipal",
				[EnumTipoFeriado.PontoFacultativo]: "pontoFacultativo",
				[EnumTipoFeriado.Outros]: "outros",
			};

			// Extrair os dados necessários do objeto capacidade
			const data = {
				ano: capacidade.ano,
				totalDiasAno: capacidade.extras.diasAno,
				diasNaoUteis: {
					sabDom: capacidade.extras.diasFinaisSemana || 0,
					ferNac:
						capacidade.extras.feriadosPorTipo?.[
							feriadoEnumToKeyMap[EnumTipoFeriado.Nacional]
						] || 0,
					ferEst:
						capacidade.extras.feriadosPorTipo?.[
							feriadoEnumToKeyMap[EnumTipoFeriado.Estadual]
						] || 0,
					ferMun:
						capacidade.extras.feriadosPorTipo?.[
							feriadoEnumToKeyMap[EnumTipoFeriado.Municipal]
						] || 0,
					pontoFac:
						capacidade.extras.feriadosPorTipo?.[
							feriadoEnumToKeyMap[
								EnumTipoFeriado.PontoFacultativo
							]
						] || 0,
					outros:
						capacidade.extras.feriadosPorTipo?.[
							feriadoEnumToKeyMap[EnumTipoFeriado.Outros]
						] || 0,
					totalDNU: capacidade.extras.totalDiasNaoUteis || 0,
				},
				du: capacidade.extras.du || 0,
				tipoDispensa: capacidade.extras.tipoDispensa,
				totalDispensasPadrao:
					capacidade.extras.totalDispensasPadrao || 0,
				diasDisponiveisTotais:
					capacidade.extras.diasDisponiveisTotais || 0,
				diasReservadosAuditoriasEspeciais:
					capacidade.extras.diasReservadosAuditoriasEspeciais || 0,
				diasDisponiveis: capacidade.extras.diasDisponiveis || 0,
				auditoriasEspeciais: {
					id: capacidade.extras.auditoriasEspeciaisGet.id,
					valor: capacidade.extras.auditoriasEspeciaisGet.valor,
				},
				auditoriaEspecialDD: capacidade.extras.auditoriaEspecialDD,
			};

			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "1080px";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = {
				capacidade: data,
				coordenacao,
				visualizar,
			};

			this.matDialog.open(ModalDescricaoDiasUteisComponent, dialogConfig);
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async descricaoPEAC(
		capacidade: IPeaCoordenacaoDiasUteisModel,
		visualizar?: boolean
	) {
		try {
			const coordenacao = (
				await this.coordenacaoService.obterCapacidadePorAno(
					capacidade.coordenacaoId,
					capacidade.ano
				)
			).dados;

			// Extrair os dados necessários do objeto capacidade
			const data = {
				capacidade: {
					diasDisponiveis: capacidade.extras.diasDisponiveis,
					qntDiasPorAuditor: capacidade.extras.qntDiasPorAuditor,
					numAuditoriaPorEquipe:
						capacidade.extras.numAuditoriaPorEquipe,
					numAuditoriaPorEquipeArred:
						capacidade.extras.numAuditoriaPorEquipeArred,
					numEquipeDisponiveis:
						capacidade.extras.numEquipeDisponiveis,
					capacidadeTotalPorCoord:
						capacidade.extras.capacidadeTotalPorCoord,
				},
			};
			// Configuração do modal
			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-descricao-paac-component";
			dialogConfig.width = "600px";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = data;

			// Abrir o modal
			const modal = this.matDialog.open(
				ModalDescricaoPEACComponent,
				dialogConfig
			);
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async editarCapacidade(
		capacidade: IPeaCoordenacaoCapacidadeModel,
		visualizar?: boolean
	) {
		try {
			const coordenacao = (
				await this.coordenacaoService.obterCapacidadePorAno(
					capacidade.coordenacaoId,
					capacidade.ano
				)
			).dados;

			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "1080px";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = {
				capacidade,
				coordenacao,
				visualizar,
			};
			const modal = this.matDialog.open(
				ModalCapacidadeComponent,
				dialogConfig
			);
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async salvarDados(salvarEFechar: boolean) {
		this.submit = true;

		if (this.form.invalid) {
			this.exibirMensagemErro("Formulário invalido!");
			return;
		}

		if (
			this.ugDataSource.data.length === 0 ||
			this.coordenacoesDataSource.data.length === 0
		) {
			this.exibirMensagemErro(
				"Informe alguma Unidade Gestora e alguma Coordenação"
			);
			return;
		}

		this.atualizarModel(this.form.value);

		try {
			let res: IBaseModel<any> = null;
			Object.assign(this.modelDadosBasicos, this.form.value);
			if (this.novoRegistro) {
				res = await this.peaService.inserir(this.modelDadosBasicos);
			} else {
				switch (this.aba) {
					case 0:
						res = await this.atualizarAbaDadosBasicos();
						break;
					case 4:
						res = await this.atualizarAbaDistribuicao();
						if (res.sucesso) {
							this.obterAbaDistribuicao();
						}
						break;
					default:
						console.warn("salvar não implementado");
				}
			}

			if (res.sucesso) {
				this.exibirMensagemSucesso(res.mensagem.descricao);
				this.salvarDadosPendente = false;
				if (
					res.dados?.pendencias?.pendencias &&
					res.dados?.pendencias?.pendencias.length > 0
				)
					this.showPendencias(res?.dados?.pendencias?.pendencias);
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
				return;
			}

			if (salvarEFechar == true) this.router.navigate(["/pea"]);
			else {
				if (this.novoRegistro)
					this.router.navigate([`/pea/${res.dados.id}`]);
				await this.obterDadosBasicos();
			}

			if (res.sucesso) {
				if (
					res.dados?.pendencias?.pendencias &&
					res.dados?.pendencias?.pendencias.length > 0
				) {
					this.showPendencias(res?.dados?.pendencias?.pendencias);
				}
			} else {
				const validacoes = this.montarMensagensValidacao(res);

				if (validacoes.trim().length > 0)
					this.exibirMensagemErro(validacoes);
			}
		} catch (err) {
			this.exibirMensagemErro(err?.mensagem?.descricao);
		}
	}

	public async atualizarAbaDadosBasicos() {
		this.modelDadosBasicos.peaUnidadeGestora = this.ugDataSource.data;
		this.modelDadosBasicos.peaCoordenacao =
			this.coordenacoesDataSource.data;
		return this.peaService.atualizarDadosBasicos(this.modelDadosBasicos);
	}

	public async atualizarAbaDistribuicao() {
		if (this.model.classificacaoPersonalizada) {
			this.model.classificacaoPisoBaixo = this.modelFaixa?.pisoBaixo ?? 0;
			this.model.classificacaoTetoBaixo = this.modelFaixa?.tetoBaixo ?? 0;
			this.model.classificacaoPisoMedio = this.modelFaixa?.pisoMedio ?? 0;
			this.model.classificacaoTetoMedio = this.modelFaixa?.tetoMedio ?? 0;
			this.model.classificacaoPisoAlto = this.modelFaixa?.pisoAlto ?? 0;
			this.model.classificacaoTetoAlto = this.modelFaixa?.tetoAlto ?? 0;
		} else {
			this.model.classificacaoPisoBaixo = null;
			this.model.classificacaoTetoBaixo = null;
			this.model.classificacaoPisoMedio = null;
			this.model.classificacaoTetoMedio = null;
			this.model.classificacaoPisoAlto = null;
			this.model.classificacaoTetoAlto = null;
		}

		this.model.validacaoFluxoAprovacao = null;
		this.model.id = this.id;

		return this.peaService.AtualizarFaixaClassificacao(this.model);
	}

	public async salvarDadosDistribuicao(salvarEFechar: boolean) {
		this.submit = true;
		if (this.form.invalid) {
			this.exibirMensagemErro("Formulário invalido!");
			return;
		}

		this.atualizarModel(this.form.value);

		if (this.model.classificacaoPersonalizada) {
			this.model.classificacaoPisoBaixo = this.modelFaixa?.pisoBaixo ?? 0;
			this.model.classificacaoTetoBaixo = this.modelFaixa?.tetoBaixo ?? 0;
			this.model.classificacaoPisoMedio = this.modelFaixa?.pisoMedio ?? 0;
			this.model.classificacaoTetoMedio = this.modelFaixa?.tetoMedio ?? 0;
			this.model.classificacaoPisoAlto = this.modelFaixa?.pisoAlto ?? 0;
			this.model.classificacaoTetoAlto = this.modelFaixa?.tetoAlto ?? 0;
		} else {
			this.model.classificacaoPisoBaixo = null;
			this.model.classificacaoTetoBaixo = null;
			this.model.classificacaoPisoMedio = null;
			this.model.classificacaoTetoMedio = null;
			this.model.classificacaoPisoAlto = null;
			this.model.classificacaoTetoAlto = null;
		}

		this.model.validacaoFluxoAprovacao = null;

		try {
			let res: IBaseModel<IPeaModel> = null;

			res = await this.peaService.atualizar(this.model);

			if (res.sucesso) {
				this.exibirMensagemSucesso(res.mensagem.descricao);

				if (salvarEFechar == true) this.router.navigate(["/pea"]);
			} else {
				const validacoes = this.montarMensagensValidacao(res);
				if (validacoes.trim().length > 0) {
					this.exibirMensagemErro(validacoes);
				} else {
					this.exibirMensagemErro(res.mensagem.descricao);
				}
			}
		} catch (err) {
			this.exibirMensagemErro(err?.mensagem?.descricao);
		}
	}

	private atualizarModel(values: any) {
		Object.assign(this.model, values);
	}

	public async enviarAprovar() {
		const perfis = await this.authService.obterPerfisSimplificado();
		const inputOptions = new Map();

		perfis.dados.forEach((element) => {
			inputOptions.set(element.guid, element.nome);
		});

		Swal.fire({
			title: "Enviar para aprovação",
			text: "Selecione seu Perfil de envio para aprovação",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Enviar",
			cancelButtonText: "Cancelar",
			input: "select",
			inputOptions,
		}).then((res) => {
			if (res.isConfirmed) {
				this.model.situacaoId = 2;
				this.peaService
					.enviarAprovacao(this.id, res.value.toString())
					.then((res) => {
						if (res.sucesso) {
							this.exibirMensagemSucesso(
								"Encaminhado para aprovação!"
							);
							this.router.navigate([`/pendencias`]);
						} else {
							this.exibirMensagemErro(res.mensagem.descricao);
						}
					})
					.catch((err) => {
						this.exibirMensagemErro(err.mensagem.descricao);
					})
					.finally(() => {
						this.obterDados();
					});
			}
		});
	}

	public async exibirModalPeriodo() {
		try {
			const res = await this.periodoService.obter("", true);
			if (res.sucesso) {
				res.dados.sort(
					(d2, d1) =>
						new Date(d1.dataInicio).getTime() -
						new Date(d2.dataInicio).getTime()
				);
				const modal = this.exibirModal("Selecionar Periodo", res.dados);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						this.modelDadosBasicos.periodo = data;
						this.modelDadosBasicos.periodoId = data.id;
						this.form.controls["periodoId"].setValue(data.id);

						// if (!this.novoRegistro) {
						//   this.salvarDados(false);
						//   this.obterDadosBasicos();
						// }
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalIndicador() {
		try {
			const res = await this.indicadorService.obter("", true);
			if (res.sucesso) {
				res.dados.sort((a, b) => a["nome"].localeCompare(b["nome"]));
				const modal = this.exibirModal(
					"Selecionar Indicador",
					res.dados
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						this.modelDadosBasicos.indicador = data;
						this.modelDadosBasicos.indicadorId = data.id;
						this.form.controls["indicadorId"].setValue(data.id);
						// if (!this.novoRegistro) {
						//   this.salvarDados(false);
						//   this.obterDados();
						// }
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalUg() {
		try {
			const res = await this.ugService.obterAtivos();
			if (res.sucesso) {
				const dialogConfig = new MatDialogConfig();
				dialogConfig.id = "modal-component";
				dialogConfig.width = "650px";
				dialogConfig.hasBackdrop = true;
				dialogConfig.disableClose = true;
				const unidadesAdicionadas = [] as IUnidadeGestoraModel[];
				if (this.modelDadosBasicos.peaUnidadeGestora !== undefined) {
					this.modelDadosBasicos.peaUnidadeGestora.forEach((d) =>
						unidadesAdicionadas.push(d.unidadeGestora)
					);
					dialogConfig.data = res.dados.filter(
						(d) => !unidadesAdicionadas.some((x) => d.id === x.id)
					);
				} else {
					dialogConfig.data = res.dados;
					this.modelDadosBasicos.peaUnidadeGestora =
						[] as IPeaUnidadeGestoraModel[];
				}
				const modal = this.matDialog.open(
					ModalSelecionarUgComponent,
					dialogConfig
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						if (Array.isArray(data)) {
							let unidadeSelecionada =
								[] as IPeaUnidadeGestoraModel[];
							unidadeSelecionada = data.map(
								(d) =>
									({
										unidadeGestoraId: d.id,
										unidadeGestora: d,
									} as IPeaUnidadeGestoraModel)
							);
							unidadeSelecionada.forEach((x) =>
								this.modelDadosBasicos.peaUnidadeGestora.push(x)
							);
						}

						this.atualizarListaUgs(
							this.modelDadosBasicos.peaUnidadeGestora
						);
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalCoordenacoes() {
		try {
			const res = await this.coordenacaoService.obter("", true);
			if (res.sucesso) {
				const dialogConfig = new MatDialogConfig();
				dialogConfig.id = "modal-component";
				dialogConfig.width = "650px";
				dialogConfig.hasBackdrop = true;
				dialogConfig.disableClose = true;
				const coordenacoesAdicionadas = [] as ICoordenacaoModel[];
				if (this.modelDadosBasicos.peaCoordenacao !== undefined) {
					this.modelDadosBasicos.peaCoordenacao.forEach((d) =>
						coordenacoesAdicionadas.push(d.coordenacao)
					);
					dialogConfig.data = res.dados.filter(
						(d) =>
							!coordenacoesAdicionadas.some((x) => d.id === x.id)
					);
				} else {
					dialogConfig.data = res.dados;
					this.modelDadosBasicos.peaCoordenacao =
						[] as IPeaCoordenacaoModel[];
				}
				const modal = this.matDialog.open(
					ModalSelecionarCoordenacoesComponent,
					dialogConfig
				);
				modal.afterClosed().subscribe((data) => {
					if (data) {
						this.salvarDadosPendente = true;
						if (Array.isArray(data)) {
							let coordenacaoSelecionada =
								[] as IPeaCoordenacaoModel[];
							coordenacaoSelecionada = data.map(
								(d) =>
									({
										coordenacaoId: d.id,
										coordenacao: d,
									} as IPeaCoordenacaoModel)
							);
							coordenacaoSelecionada.forEach((x) =>
								this.modelDadosBasicos.peaCoordenacao.push(x)
							);
						}

						this.coordenacoesDataSource =
							new MatTableDataSource<IPeaCoordenacaoModel>(
								this.modelDadosBasicos.peaCoordenacao
							);
						this.coordenacoesDataSource._updateChangeSubscription();
						this.coordenacaoSemDados =
							this.coordenacoesDataSource.filteredData.length ===
							0;
					}
				});
			} else {
				this.exibirMensagemErro(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public avancar() {
		this.form.markAllAsTouched();

		let invalid = false;

		if (this.form.invalid) {
			this.exibirMensagemErro("Formulário inválido");
			invalid = true;
		}
		if (
			!this.model.peaCoordenacao ||
			this.model.peaCoordenacao.length === 0
		) {
			this.exibirMensagemErro(
				"É obrigatório a seleção de pelo menos uma Coordenação!"
			);
			invalid = true;
		}

		if (
			!this.model.peaUnidadeGestora ||
			this.model.peaUnidadeGestora.length === 0
		) {
			this.exibirMensagemAlerta(
				"É obrigatório a seleção de pelo menos uma Unidade Gestora!"
			);
			invalid = true;
		}

		if (invalid) {
			return;
		}

		this.aba++;
	}

	public voltar() {
		this.aba--;
	}

	public validar(): boolean {
		return this.form.valid;
	}

	public excluirUg(ind: number) {
		Swal.fire({
			title: "Excluir Unidade Gestora",
			text: "Deseja realmente excluir a informação?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((resConfirm) => {
			if (resConfirm.isConfirmed) {
				this.salvarDadosPendente = true;
				this.ugDataSource.data.splice(ind, 1);
				this.atualizarListaUgs(this.ugDataSource.data);
			}
		});
	}

	public excluirCoordenacao(ind: number) {
		Swal.fire({
			title: "Excluir Coordenação",
			text: "Deseja realmente excluir a informação?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
		}).then((resConfirm) => {
			if (resConfirm.isConfirmed) {
				this.salvarDadosPendente = true;
				this.coordenacoesDataSource.data.splice(ind, 1);
				this.coordenacoesDataSource._updateChangeSubscription();
				this.coordenacaoSemDados =
					this.coordenacoesDataSource.filteredData.length === 0;
			}
		});
	}

	//0- Dados Básicos | 1-CargasCriterios | 2-Capacidade | 3-Ranking | 4-Distribuicao | 5-Relatoria | 6-LinhaDoTempo
	public async mudarAba(ind: number) {
		if (this.novoRegistro) {
			this.exibirMensagemErro(
				"Necessário salvar os dados antes de mudar de aba."
			);
			return;
		}

		this.aba = ind;

		switch (ind) {
			case 0:
				this.obterDadosBasicos();
				break;
			case 1:
				this.obterAbaCargaCriterios();
				break;
			case 2:
				this.obterAbaCapacidadeTrabalho();
				break;
			case 3:
				this.obterAbaRanking();
				break;
			case 4:
				await this.obterAbaDistribuicao();
				break;
			case 5:
				this.obterDados();
				break;
			default:
				console.warn("Aba não implementada");
				break;
		}
	}

	public importarArquivoCriterio(id: number) {
		try {
			const respostas = this.ugDataSource.data.map(
				(u) =>
					({
						peaId: this.id,
						criterioId: id,
						unidadeGestoraId: u.unidadeGestoraId,
						unidadeGestora: u.unidadeGestora,
					} as IPeaCriterioRespostaModel)
			);

			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "700px";
			dialogConfig.maxHeight = "700px";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.data = respostas;
			const modal = this.matDialog.open(
				ModalImportarArquivoCriterioComponent,
				dialogConfig
			);
			modal.afterClosed().subscribe(async (data) => {
				if (data) {
					try {
						if (!this.novoRegistro) {
							const res =
								await this.peaService.enviarRespostasCriterio(
									this.id,
									respostas.map((c) => ({
										peaId: c.peaId,
										criterioId: c.criterioId,
										unidadeGestoraId: c.unidadeGestoraId,
										valorNumerico: c.valorNumerico,
									}))
								);
							if (res.sucesso) {
								await Promise.all([
									this.obterDados(),
									this.obterPainelDistribuicao(this.id),
									this.peaService
										.obterCargaCriteriosPorId(this.id)
										.then((resCriterios) => {
											this.criterioDataSource =
												new MatTableDataSource<ICargaCriterioPeaModel>(
													resCriterios.dados
												);
											this.criterioDataSource._updateChangeSubscription();
											this.criterioSemDados =
												this.criterioDataSource
													.filteredData.length === 0;
										}),
								]);
								this.exibirMensagemSucesso(
									res.mensagem.descricao
								);
							} else {
								this.exibirMensagemAlerta(
									res.mensagem.descricao
								);
							}
						}
					} catch (err) {
						this.exibirMensagemErro(err.mensagem.descricao);
						this.router.navigate(["/pea"]);
					}
				}
			});
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public preencherFormularioCriterio(id: number) {
		try {
			const criterio = this.criterioDataSource.data.find(
				(c) => c.criterioId === id
			);
			criterio.peaCriterioResposta = criterio.peaCriterioResposta || [];

			let respostas = this.ugDataSource.data.map((u) => {
				return (
					criterio.peaCriterioResposta.find(
						(r) => r.unidadeGestoraId === u.unidadeGestoraId
					) ||
					({
						peaId: this.id,
						criterioId: id,
						criterio: criterio.criterio,
						unidadeGestoraId: u.unidadeGestoraId,
						unidadeGestora: u.unidadeGestora,
					} as IPeaCriterioRespostaModel)
				);
			});

			respostas = respostas.sort((a, b) => {
				return (
					+(a.unidadeGestora.nome > b.unidadeGestora.nome) ||
					+(a.unidadeGestora.nome === b.unidadeGestora.nome) - 1
				);
			});

			const dialogConfig = new MatDialogConfig();
			dialogConfig.id = "modal-component";
			dialogConfig.width = "700px";
			dialogConfig.hasBackdrop = true;
			dialogConfig.disableClose = true;
			dialogConfig.ariaLabel = criterio.criterio.nome;
			dialogConfig.data = respostas.sort((a, b) =>
				a["unidadeGestora"].nome.localeCompare(b["unidadeGestora"].nome)
			);

			const modal = this.matDialog.open(
				ModalPreencherFormularioComponent,
				dialogConfig
			);
			modal.afterClosed().subscribe(async (data) => {
				if (data) {
					try {
						if (!this.novoRegistro) {
							const res =
								await this.peaService.enviarRespostasCriterio(
									this.id,
									respostas.map((c) => ({
										peaId: c.peaId,
										criterioId: c.criterioId,
										unidadeGestoraId: c.unidadeGestoraId,
										valorNumerico: c.valorNumerico,
									}))
								);
							if (res.sucesso) {
								await Promise.all([
									this.obterDados(),
									this.obterPainelDistribuicao(this.id),
									this.peaService
										.obterCargaCriteriosPorId(this.id)
										.then((resCriterios) => {
											this.criterioDataSource =
												new MatTableDataSource<ICargaCriterioPeaModel>(
													resCriterios.dados
												);
											this.criterioDataSource._updateChangeSubscription();
											this.criterioSemDados =
												this.criterioDataSource
													.filteredData.length === 0;
										}),
								]);
								this.exibirMensagemSucesso(
									res.mensagem.descricao
								);
							} else {
								this.exibirMensagemErro(res.mensagem.descricao);
								this.router.navigate(["/pea"]);
								return;
							}
						}
					} catch (err) {
						this.exibirMensagemErro(err.mensagem.descricao);
						this.router.navigate(["/pea"]);
					}
				}
			});
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public exibirRespostas(id: number) {
		const respostas =
			(this.criterioDataSource.data.find((c) => c.criterioId === id)
				.peaCriterioResposta as []) || [];
		const questionario =
			(this.criterioDataSource.data.find((c) => c.criterioId === id)
				.peaCriterioQuestionario as []) || [];

		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "700px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		dialogConfig.data = respostas
			.concat(questionario)
			.sort((a: ICargaCriterioPeaModel, b: ICargaCriterioPeaModel) =>
				a["unidadeGestora"].nome.localeCompare(b["unidadeGestora"].nome)
			);
		this.matDialog.open(ModalVisualizarRespostasComponent, dialogConfig);
	}

	public formatarNumero(n: number) {
		return n?.toFixed(4).toString().replace(".", ",");
	}

	public async enviarQuestionario(
		modelEnviarQuestionario: IEnviarQuestionarioModel
	) {
		try {
			if (!this.novoRegistro) {
				const res = await this.peaService.enviarQuestionarios(
					this.id,
					modelEnviarQuestionario
				);
				if (res.sucesso) {
					this.exibirMensagemSucesso(res.mensagem.descricao);
					this.obterCargaCriterios();
				} else {
					this.exibirMensagemAlerta(res.mensagem.descricao);
					return;
				}
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async aprovar() {
		try {
			const res = await this.peaService.aprovar(this.id);
			if (res.sucesso) {
				this.exibirMensagemSucesso("Item aprovado com sucesso!");
				this.router.navigate(["/pendencias"]);
			} else {
				this.exibirMensagemAlerta(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public async exibirModalEnviarQuestionarioCriterio() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "650px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		const modal = this.matDialog.open(
			ModalEnviarQuestionarioCriterioComponent,
			dialogConfig
		);
		modal.afterClosed().subscribe((data) => {
			if (data) {
				this.enviarQuestionario(data);
			}
		});
	}

	public async exibirModalVisualizarQuestionarioCriterio() {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.id = "modal-component";
		dialogConfig.width = "850px";
		dialogConfig.hasBackdrop = true;
		dialogConfig.disableClose = true;
		const res = await this.peaService.obterVisualizacaoQuestionario(
			this.id
		);
		dialogConfig.data = res;
		const modal = this.matDialog.open(
			ModalVisualizarQuestionarioCriterioComponent,
			dialogConfig
		);
	}

	public async confirmarCancelamento() {
		const res = await this.peaService.questionariosRespondidos(this.id);
		if (res.sucesso) {
			Swal.fire({
				title: "Cancelar Questionário",
				text: "Deseja cancelar o envio dos questionarios?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
			}).then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.cancelarEnvioQuestionario();
				}
			});
		} else if (res.mensagem.nome === "QuestionarioRespondido") {
			Swal.fire({
				title: "Cancelar Questionário",
				text: `${res.mensagem?.descricao}. Deseja cancelar o envio mesmo assim?`,
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sim",
				cancelButtonText: "Não",
			}).then((resConfirm) => {
				if (resConfirm.isConfirmed) {
					this.cancelarEnvioQuestionario();
				}
			});
		} else {
			this.exibirMensagemErro(res.mensagem.descricao);
		}
	}

	public async cancelarEnvioQuestionario() {
		try {
			const res = await this.peaService.enviarQuestionarioCancelado(
				this.id
			);
			if (res.sucesso) {
				this.exibirMensagemSucesso("Envio cancelado");
				this.obterCargaCriterios();
			} else {
				this.exibirMensagemAlerta(res.mensagem.descricao);
			}
		} catch (err) {
			this.exibirMensagemErro(err.mensagem.descricao);
		}
	}

	public obterRankingPrioridade() {
		this.rankingService
			.obterRankingDistribuicao(this.id, this.model.indicadorId, 1)
			.then((res) => {
				const lista = res.dados
					.filter((_) => !_.unidadeGestora.empresaPublica)
					.map((_) => ({
						..._,
						nome: _.unidadeGestora.nome,
						sigla: _.unidadeGestora.sigla,
						codigoUg: _.unidadeGestora.codigoUg,
					}));
				this.rankingPrioridadeDataSource =
					new MatTableDataSource<IPeaRankingPrioridadeModel>(lista);
				this.rankingPrioridadeDataSource._updateChangeSubscription();
				this.semDadosRanking =
					this.rankingPrioridadeDataSource.data.length === 0;

				const listaPublicas = res.dados
					.filter((_) => _.unidadeGestora.empresaPublica)
					.map((_) => ({
						..._,
						nome: _.unidadeGestora.nome,
						sigla: _.unidadeGestora.sigla,
						codigoUg: _.unidadeGestora.codigoUg,
					}));
				this.rankingPrioridadeEmpresasPublicasDataSource =
					new MatTableDataSource<IPeaRankingPrioridadeModel>(
						listaPublicas
					);
				this.rankingPrioridadeEmpresasPublicasDataSource._updateChangeSubscription();
				this.semDadosRankingEmpresaPublica =
					this.rankingPrioridadeEmpresasPublicasDataSource.data
						.length === 0;
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem.descricao);
			});
	}

	public gerarRankingDistribuicao() {
		this.rankingService
			.gerarRankingDistribuicao(this.id, 1)
			.then((res) => {
				this.showPendencias(res?.dados?.pendencias);
				this.obterDados();
				this.obterRankingPrioridade();
			})
			.catch((err) => {
				this.exibirMensagemErro(err.mensagem.descricao);
			});
	}

	public async removerClassificacaoPersonalizada() {
		Swal.fire({
			title: "Confirmação",
			text: "Deseja realmente remover a classificação personalizada?",
			icon: "warning",
			confirmButtonText: "Sim",
			cancelButtonText: "Não",
			showCancelButton: true,
		}).then((res) => {
			if (res.isConfirmed) {
				this.peaService
					.removerClassificacaoPersonalizada(this.model.id)
					.then((response) => {
						if (response.sucesso) {
							this.exibirMensagemSucesso(
								"Classificação personalizada removida com sucesso."
							);
							this.distribuicaoPersonalizadoDataSource =
								new MatTableDataSource<any>([]);
							this.distribuicaoPersonalizadoDataSource._updateChangeSubscription();
							this.model.classificacaoPersonalizada = false;
							this.distribuicaoSemDadosPersonalizado = true;
							this.obterAbaDistribuicao();
						} else {
							this.exibirMensagemAlerta(
								response.mensagem.descricao,
								false
							);
						}
					});
			}
		});
	}
}
