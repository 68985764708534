import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { BaseFormComponent } from "../../../../shared/components/base-form/base-form.component";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../../services/auth.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { PerguntaService } from "../../../../services/pergunta.service";
import { TabelaDominioService } from "../../../../services/tabela-dominio.service";
import { MatDialog } from "@angular/material/dialog";
import { MatGridListModule } from "@angular/material/grid-list";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";
import {
  IUniversoAuditavelItemRespostaItensModel,
  IUniversoAuditavelModel
} from "../../../../models/universo-auditavel.model";
import { TipoUniversoEnum } from "../../../../models/enum/tipo-universo.enum";
import { TipoUniversoService } from "../../../../services/tipo-universo.service";
import { UnidadeGestoraService } from "../../../../services/unidade-gestora.service";
import {
  ITipoUniversoItemModel,
  ITipoUniversoModel,
  ITipoUniversoUGModel
} from "../../../../models/tipo-universo.model";
import { UniversoAuditavelItemRespostaService } from "../../../../services/universo-auditavel-item-resposta.service";
import { MatTableDataSource } from "@angular/material/table";
import { element } from "protractor";

@Component({
  selector: 'app-visualizar-universo-auditavel',
  templateUrl: './visualizar-universo-auditavel.component.html',
  styleUrls: ['./visualizar-universo-auditavel.component.scss']
})

export class VisualizarUniversoAuditavelComponent extends BaseFormComponent implements OnInit{
  constructor(
    toastr: ToastrService,
    route: ActivatedRoute,
    router: Router,
    private authService: AuthService,
    localeService: BsLocaleService,
    private perguntaService: PerguntaService,
    private tabelaDominioService: TabelaDominioService,
    private universoAuditavelService: UniversoAuditavelService,
    private universoAuditavelItemService: UniversoAuditavelItemRespostaService,
    public matDialog: MatDialog,
    private tipoUniversoService: TipoUniversoService,
    private unidadeGestoraService: UnidadeGestoraService,
    public matGridListModule: MatGridListModule
  ) {
    super(route, toastr, router, localeService, matDialog);
    this.dataSource = new MatTableDataSource<IUniversoAuditavelItemRespostaItensModel>();

  }
  public modelUniversoAuditavel = {} as IUniversoAuditavelModel;
  public dropdownData: { value: string, text: string , unidade: string}[] = [];
  public semDados: boolean;
  public modelTipoUniverso: ITipoUniversoModel = {} as ITipoUniversoModel;
  public modelTipoUniversoItens: IUniversoAuditavelItemRespostaItensModel = {} as IUniversoAuditavelItemRespostaItensModel;
  unidadeGestoraNome: string = '';
  indicadorNome: string = '';
  unidadeGestora: number;
  totalItens: number;
  colspanIdicador: number;
  criterios: string[] = [];
  dataSource: MatTableDataSource<IUniversoAuditavelItemRespostaItensModel>;
  displayedColumns: string[] = ['subtipo', 'macroprocesso', 'processos', ...this.criterios];
  indicador: string[] = ['', '', '', '','indicador'];

  async ngOnInit() {
    const res = await this.universoAuditavelService.obterPorId(this.id);
    if ( res.sucesso){
      this.modelUniversoAuditavel = res.dados;
    }

    this.buscarTipoUniverso(this.modelUniversoAuditavel.tipoUniversoAuditavelId);
  }

  getDivididosPorColunas(itemMacro: any) {
    const processos = itemMacro.itens.filter(
      processo => processo.idItemPai === itemMacro.tipoUniversoAuditavelItemId && processo.criterioId
    );

    const coluna1 = processos.slice(0, Math.ceil(processos.length / 2));
    const coluna2 = processos.slice(Math.ceil(processos.length / 2));

    return [coluna1, coluna2];
  }

  getCriterioValue(element: IUniversoAuditavelItemRespostaItensModel, criterioNome: string): string[] {
    let respostas: string[] = [];

    if (element.itens.length > 0) {
      for (const value of element.itens) {
        const respostasEncontradas = value.itens
          .filter(resp => value.tipoUniversoAuditavelItemId === resp.idItemPai && resp.criterioNome === criterioNome)
          .map(resp => resp.universoAuditavelItemRespostas);

        respostas.push(...respostasEncontradas);
      }
    }

    return respostas; // Retorna um array de respostas
  }


  public async buscarTipoUniverso(idTipo:number){
    const res = await this.tipoUniversoService.obterPorId(idTipo);
    if(res.sucesso){
      this.modelTipoUniverso =  res.dados;
      this.unidadeGestora  = this.modelUniversoAuditavel.unidadeGestoraId;
      this.titulo = 'Visualizar Mapeamento Universo Auditável - '+ this.modelTipoUniverso.nome;

      const unidade = await this.unidadeGestoraService.obterPorId(this.unidadeGestora);

      if (unidade.sucesso) {
        this.unidadeGestoraNome = unidade.dados.sigla + '-' + unidade.dados.nome;
      }
    }

    this.buscarItens(this.modelTipoUniverso.id, this.unidadeGestora );
  }

  contarItens(obj: any): number {

    return obj.itens.reduce((acc: number, item: any) => {
      return acc + 1 + (item.itens ? this.contarItens(item) : 0);
    }, 0);
  }

  public cancelar() {
    this.router.navigateByUrl(
      `/unidades-gestoras/${this.unidadeGestora}?aba=3`
    );
  }

  public async buscarItens(idTipoUniverso: number, idUG: number) {

    if (idTipoUniverso) {
      const res = await this.universoAuditavelItemService.ObterRespostaPorCriterios(idUG, idTipoUniverso);
      if (res.sucesso) {
        this.modelTipoUniversoItens = res.dados;
        this.reordenar();
        this.processData();

      }
    }
  }
  contarItensTipo2(obj) {
    function contarItens(itens) {
      return itens.reduce((total, item) => {
        let count = item.tipo === 2 ? item.itens.length : 0;
        return total + count + contarItens(item.itens);
      }, 0);
    }
    return contarItens(obj.itens);
  }

  retornoAbaUnidade(){
    this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestora}?aba=3`);
  }

  processData() {
    if (!this.modelTipoUniversoItens || Object.keys(this.modelTipoUniversoItens).length === 0) {
      this.dataSource.data = [];
      return;
    }

    const criteriosSet = new Set<string>();
    Object.values(this.modelTipoUniversoItens).forEach((value, idx) => {
      this.totalItens = this.contarItensTipo2(value);
    });


    Object.values(this.modelTipoUniversoItens).forEach((value, idx) => {
      this.processItemsForCriterios(value, criteriosSet);
    });

    this.criterios = Array.from(criteriosSet);
    Object.values(this.modelTipoUniversoItens).forEach((value, idx) => {
      if(idx === 0){
        this.indicadorNome = value.indicadorDescricao;
      }
    });

    this.colspanIdicador = this.criterios.length ;
    this.displayedColumns = [
      'subtipo',
      'macroprocesso',
      'processos',
      ...this.criterios.map(c => `criterio_${c}`)
    ];
    this.dataSource.data = Object.values(this.modelTipoUniversoItens);
  }

  processItemsForCriterios(item: IUniversoAuditavelItemRespostaItensModel, criteriosSet: Set<string>) {
    if (item.criterioNome) {
      criteriosSet.add(item.criterioNome);
    }

    if (item.itens && Object.values(item.itens).length > 0) {
      Object.values(item.itens).forEach(value => {
        this.processItemsForCriterios(value, criteriosSet);
      });
    }
  }


  public reordenar() {
    // Verifica se model é um objeto com as chaves e percorre as chaves
    Object.keys(this.modelTipoUniversoItens).forEach((key, idx) => {
      this.reordenarItem(this.modelTipoUniversoItens[key], idx + 1, '');  // Começa a ordem com uma string vazia
    });
  }

  reordenarItem(item: IUniversoAuditavelItemRespostaItensModel, index: number, prefixo: string) {
    const novaOrdem = prefixo ? `${prefixo}.${index}` : `${index}`;
    item.ordemExibicao = novaOrdem;

    if (item.itens && Object.keys(item.itens).length > 0) {
      Object.keys(item.itens).forEach((key, idx) => {
        this.reordenarItem(item.itens[key], idx + 1, novaOrdem);
      });
    }
  }

  public onBack() {

    this.submit = false;

    this.router.navigateByUrl(`/unidades-gestoras/${this.modelUniversoAuditavel.unidadeGestoraId}?aba=3`);
  }

}
