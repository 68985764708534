import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  ModalUniversoAuditavelImportaDadosComponent
} from "../modal-universo-auditavel-importa-dados/modal-universo-auditavel-importa-dados.component";


@Component({
  selector: 'app-modal-universo-auditavel-nova-versao',
  templateUrl: './modal-universo-auditavel-nova-versao.component.html',
  styleUrls: ['./modal-universo-auditavel-nova-versao.component.scss']
})
export class ModalUniversoAuditavelNovaVersaoComponent implements OnInit{
  private currentDialogRef: MatDialogRef<any>;
  constructor(
    private dialogRef: MatDialogRef<ModalUniversoAuditavelNovaVersaoComponent>,
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  public Sim() {
    if(this.data.source === 'importarArquivo')
    {
      this.dialogRef.close();
      this.router.navigate([`importacao-arquivo-universo-auditavel-shared`]);
    }else {
      this.dialogRef.close();
      const dialogConfig = new MatDialogConfig();
      dialogConfig.id = 'modal2-component';
      dialogConfig.width = '650px';
      dialogConfig.hasBackdrop = true;
      dialogConfig.disableClose = true;
      dialogConfig.data = {
        unidadeGestoraId: this.data.unidadeGestoraId,
        tipoUniversoId:  this.data.tipoUniversoId
      };

      const modal = this.matDialog.open(ModalUniversoAuditavelImportaDadosComponent, dialogConfig);

      modal.afterClosed().subscribe((data) => {

      })
    }

  }

  public Nao() {
    this.router.navigateByUrl(
      `/unidades-gestoras/${this.data.unidadeGestoraId}?aba=3`
    );
    this.dialogRef.close();
  }

  public cancelar() {
    this.dialogRef.close();
  }
}
