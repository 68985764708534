import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ICriteriosAvaliacaoRiscos } from 'src/app/models/criterios-avaliacao-riscos.model';
import { FiltroModel } from 'src/app/models/filtro.model';
import { CriteriosAvaliacaoRiscoService } from 'src/app/services/criterios-avaliacao-risco.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-criterios-avaliacao-form-dados',
  templateUrl: './criterios-avaliacao-form-dados.component.html',
  styleUrls: ['./criterios-avaliacao-form-dados.component.scss']
})
export class CriteriosAvaliacaoFormDadosComponent implements OnInit {

  @Output() updateData: EventEmitter<any> = new EventEmitter<number>();

  criteriosList: any[] = []
  public filtro = {} as FiltroModel;

  id: number = 0
  form = new FormGroup({
    id: new FormControl({ value: '0', disabled: true }),
    nome: new FormControl('', Validators.required),
    descricao: new FormControl('', Validators.required)
  });

  visualizar = false

  constructor(
    public route: ActivatedRoute,
    private service: CriteriosAvaliacaoRiscoService
  ) { }

  ngOnInit(): void {
    this.buscar();
  }

  getDataById(e: any) {
    if (e) {
      this.service.obterPorId(e).then((r) => {
        this.form.patchValue(r.dados)
        this.updateData.emit(r.dados)
      })
    }
  }

  salvarDados(e: any) {
    let dados = this.form.getRawValue() as ICriteriosAvaliacaoRiscos
    let hasData = this.criteriosList.find(o => o.nome == dados.nome)

    if (hasData && dados?.id == 0) {
      dados.id = 0
      dados.nome = ''
      dados.descricao = ''
      this.form.patchValue(dados)
      this.exibirMensagemErro("Este registro não poderá ser salvo! Informe outro nome!");
      return
    }

    if (!dados.descricao || dados.descricao == '') {
      this.exibirMensagemErro("O campo descrição deve ser informado");
    }

    if (dados?.id == 0) {
      this.service.inserir(dados).then((res: any) => {
        this.form.patchValue(res.dados)
        this.updateData.emit(res.dados)
      })
    } else {
      this.service.atualizar(dados)
    }

    // this.getListData()
  }

  // getListData() {
  //   this.service.obter(this.filtro.pesquisa, this.filtro.ativo, false).then((res) => {
  //     this.criteriosList = res.dados
  //   })
  // }

  exibirMensagemErro(mensagem: string, html: boolean = false) {
    Swal.fire({
      toast: true,
      position: "top-end",
      icon: "error",
      html: mensagem,
      showConfirmButton: false,
      showCloseButton: true,
    });
  }

  async buscar() {
    this.service
      .obter(this.filtro.pesquisa, this.filtro.ativo, false)
      .then((res) => {
        this.criteriosList = res.dados
      })
      .catch((err) => {
        this.exibirMensagemErro(err.mensagem);
      });
  }
}
