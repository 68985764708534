<div id="modal-content-wrapper">
  <div class="card card-secondary card-outline">
    <div class="card-header bg-light">
      <h4 class="card-title mb-0">Universo Auditável Nova Versão</h4>
      <div class="card-tools">
        <button type="button" class="btn btn-tool" (click)="cancelar()"><i class="fas fa-times"></i></button>
      </div>
    </div>
    <div class="card-body">
      <div class="row pt-3">
        <div class="form-group col-12">
          <p>Deseja importar os dados da versão ativa?</p>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button type="button" class="btn btn-primary btn-sm mr-1" (click)="Sim()">
        <i class="fas fa-check"></i> Sim
      </button>
      <button type="button" class="btn btn-default btn-sm" (click)="Nao()">
        <i class="fas fa-times"></i> Não
      </button>
    </div>
  </div>
</div>
