
<div class="content">
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-12">
          <ol class="breadcrumb float-sm-left">
            <li class="breadcrumb-item">
              <a [routerLink]="['/']">
                <i class="fas fa-home"></i>
                Página Inicial
              </a>
            </li>
            <li class="breadcrumb-item">
              <a [routerLink]="['/tipo-universo']">Universo auditável</a>
            </li>
            <li class="breadcrumb-item active">{{ titulo }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="form-group col-md-12">
          <label for="selectClassificacao" class="mb-1">Unidade Gestora</label>
          <div class="input-group input-group-sm" style="width: 100%;">
            <input
              id="selectClassificacao"
              type="text"
              name="tipoTxt"
              class="form-control float-right"
              disabled
              [ngModel]="unidadeGestoraNome"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
        </div>

        <div class="form-group col-md-12">
          <label for="selectClassificacao" class="mb-1">Critério</label>
          <div class="input-group input-group-sm" style="width: 100%;">
            <select id="dropdown" (change)="onSelectionChange($event)">
              <option [ngValue]="">Selecione um Critério</option>
              <option *ngFor="let item of dropdownData" [value]="item.value">{{ item.text }}</option>
            </select>
            <p *ngIf="errorMessage" style="color: red;">{{ errorMessage }}</p>

          </div>
        </div>

        <div class="form-group col-md-12">
          <label for="unidade">Unidade de Medida:</label>
          <div class="input-group input-group-sm" style="width: 100%;">
            <input
              id="unidade"
              class="form-control float-right"
              type="text"
              [ngModelOptions]="{ standalone: true }"
              [ngModel]="unidadeSelecionada"
              disabled
            />
          </div>
        </div>

        <div class="form-group col-md-12">
          <div class="card mb-3">
            <div class="card-header">
              <label>Tipo</label>
              <div>{{ modelTipoUniverso.nome }}</div>
            </div>
            <div class="card-body">

              <form [formGroup]="formGroup">
                <div *ngFor="let subtipo of modelTipoUniversoItens.itens; let i = index" class="card mb-3">
                  <div class="card-header">
                    <label>{{ subtipo.ordemExibicao }}. {{ subtipo.nome }}</label>
                  </div>
                  <div class="card-body">
                    <div *ngFor="let macro of subtipo.itens; let j = index" class="card mb-3">
                      <div class="card-header">
                        <label>{{ macro.ordemExibicao }}. {{ macro.nome }}</label>
                      </div>
                      <div class="card-body">
                        <div *ngFor="let processo of macro.itens; let k = index" class="mb-3">
                          <label [for]="'resposta-' + processo.id">
                            {{ processo.ordemExibicao }}. {{ processo.nome }}
                          </label>
                          <input
                            type="number"
                            [id]="'resposta-' + processo.id"
                            [formControlName]="'resposta-' + processo.id"
                            class="form-control"
                            [placeholder]="'Digite o valor para ' + processo.nome"
                          />
                          <div *ngIf="formGroup.get('resposta-' + processo.id)?.hasError('required') && submitted" class="text-danger">
                            Este campo é obrigatório.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="card-footer d-flex justify-content-start align-items-left">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                type="button"
                (click)="onSubmit(false)"
              >
                <i class="far fa-save"></i> Salvar
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm dropdown-toggle dropdown-icon"
                data-toggle="dropdown"
                aria-expanded="false"
              ></button>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" (click)="onSubmit(false)">Salvar</a>
                <a class="dropdown-item" (click)="onSubmit(true)">Salvar e fechar</a>
              </div>
            </div>

            <button
            type="button"
            *ngIf="!visualizar"
            class="btn btn-success btn-sm mr"
            style="height: fit-content;"
            (click)="onFinalizar()" >
            <i class="fas fa-list"></i> Finalizar
            </button>


            <button
              [hidden]="visualizar"
              type="button"
              style="height: fit-content;"
              class="btn btn-default btn-sm mr"
              (click)="onBack()"
            >
              <i class="fas fa-times"></i> Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



