<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-lightblue">
          <div class="card-header">
            <h3 class="card-title mb-0">
              <i class="fas fa-table pr-2"></i>
              Universo Auditável
            </h3>
          </div>

          <div class="card-body p-2">
            <div class="mat-elevation-z3">
              <mat-toolbar class="table-header">
                <mat-toolbar-row>


                  <div class="input-group input-group-sm" style="width: 200px;">
                    <input type="text" name="table_search" class="form-control float-right" (keyup.enter)="buscarTipoUniversoAuditavel()"
                           placeholder="Pesquisar" [(ngModel)]="filtro.pesquisa" />
                    <div class="input-group-append">
                      <button type="button" class="btn btn-info" (click)="buscarTipoUniversoAuditavel()">
                        <i class="fas fa-search"></i>
                      </button>
                    </div>
                  </div>

                  <button type="button" class="btn btn-success btn-sm mr-1" (click)="verificaCriacao()" >
                    <i class="far fa-file"></i> Gerar Universo
                  </button>

                </mat-toolbar-row>
              </mat-toolbar>
            </div>

            <div class="card-footer">
              <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                <i class="fas fa-times"></i> Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
