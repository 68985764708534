import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ToastrService } from 'ngx-toastr';
import { IFormulaRiscoInerente } from 'src/app/models/formula-risco-inerente.model';
import { IFormulaRiscoResidual } from 'src/app/models/formula-risco-residual.model';
import { EscalasService } from 'src/app/services/escalas.service';
import { FormulaRiscoInerenteService } from 'src/app/services/formula-risco-inerente.service';
import { BaseFormComponent } from 'src/app/shared/components/base-form/base-form.component';

@Component({
  selector: 'app-modal-formula-risco-inerente',
  templateUrl: './modal-formula-risco-inerente.component.html',
  styleUrls: ['./modal-formula-risco-inerente.component.scss']
})
export class ModalFormulaRiscoInerenteComponent extends BaseFormComponent implements AfterViewInit, OnInit {

  model = {} as IFormulaRiscoInerente;
  anoSelecionado: number;
  txtBtnConcluir: string;
  campoObrigatorio = false;
  atualizando = false;
  escalasDataSource: any[] = []
  listData: any[] = []

  textoModal = 'Editar Fórmula do Risco Inerente - RI'

  operadorList: any[] = [
    { id: '+', nome: 'Soma (+)' },
    { id: '/', nome: 'Divisão (/)' },
    { id: '*', nome: 'Multiplicação (*)' },
    { id: '-', nome: 'Subtração (-)' }
  ];

  constructor(
    route: ActivatedRoute,
    private dialogRef: MatDialogRef<ModalFormulaRiscoInerenteComponent>,
    public toastr: ToastrService,
    localeService: BsLocaleService,
    private escalasService: EscalasService,
    router: Router,
    private serviceFormulaRiscoInerente: FormulaRiscoInerenteService,
    public matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    super(route, toastr, router, localeService, matDialog);

    this.model.id = data.id;
    this.model.descricao = data?.descricao;
    this.model.dadosFormula = data?.dadosFormula;
    this.model.ativo = data?.ativo;
    this.model.criteriosAvaliacaoRiscosId = data?.criteriosAvaliacaoRiscosId;

    if (!this.model.id || this.model.id == 0) {
      this.atualizando = false;
      this.txtBtnConcluir = "Salvar"
    }
    else {
      this.atualizando = true;
      this.txtBtnConcluir = "Salvar"
      this.textoModal = 'Editar Fórmula do Risco Inerente - RI'
    }
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.getEscalasByCriterios()
  }

  concluir() {
    this.model.descricao = this.getDescription(this.listData);
    this.model.dadosFormula = JSON.stringify(this.listData)
    this.model.ativo = true;

    //
    if (this.atualizando) {
      this.serviceFormulaRiscoInerente.atualizar(this.model).then((res) => { this.dialogRef.close(true); })
    } else {
      this.serviceFormulaRiscoInerente.inserir(this.model).then((res) => { this.dialogRef.close(true); })
    }
  }

  cancelar() {
    this.dialogRef.close(false);
  }

  getEscalasByCriterios() {
    this.escalasService.obter(this.model.criteriosAvaliacaoRiscosId as any, true, true).then((res) => {
      this.escalasDataSource = res?.dados
      res?.dados.forEach(() => {
        this.listData.push({ campo: '', operador: '' })
      })

      let dadosFormula = this.getDadosFormuma(this.listData, JSON.parse(this.model.dadosFormula))
      this.listData = dadosFormula
    })
  }

  getDescription(escalas: { campo: string; operador: string }[]): string {
    return escalas
      .map((e, i, arr) => i < arr.length - 1 ? `${e.campo} ${e.operador}` : e.campo)
      .join(" ")
      .trim();
    // return escalas.filter(e => e.operador !== "").map(e => `${e.campo} ${e.operador}`.trim()).join(" ").trim();
  }

  getDadosFormuma(escala: any[], dadosFormulaOld: any[]): any[] {
    return escala.map((item, index) => {
      if (index < dadosFormulaOld.length) {
        return { ...dadosFormulaOld[index] };
      }
      return item;
    });
  }

}
