import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EnumTipoQuestionarioUG } from '../models/enum/tipo-questionario-ug.enum';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private unidadeGestoraId: number;
  private universoAuditavelId: number;
  private tipoUniversoAuditavelId: number;
  private subject = new Subject<any>();
  private tipoQuestionario: EnumTipoQuestionarioUG;
  private modoVisualizarTelaUnidadeGestora : boolean;

  sendSalvarEvent() {
    this.subject.next();

  }

  setParams(unidadeGestoraId: number, universoId: number, tipouniversoId: number , acao: string): void {
    const params = { unidadeGestoraId : unidadeGestoraId, universoId : universoId, tipouniversoId: tipouniversoId, acao: acao };
    localStorage.setItem('params', JSON.stringify(params));
  }

  getParams(): any {
    const params = localStorage.getItem('params');
    return params ? JSON.parse(params) : null;
  }

  getEvent():Observable<any>{
    return this.subject.asObservable();
  }

  setId(id: number) {
    this.unidadeGestoraId = id;
  }

  getId(): number {
    return this.unidadeGestoraId;
  }

  setTipoQuestionario(tipo: EnumTipoQuestionarioUG){
    this.tipoQuestionario = tipo;
  }

  getTipoQuestionario(): EnumTipoQuestionarioUG{
    return this.tipoQuestionario;
  }

  setModoVisualizacaoAbasUnidadeGestora(modoVisualizacao: boolean) {
    this.modoVisualizarTelaUnidadeGestora = modoVisualizacao;
  }

  getModoVisualizacaoAbasUnidadeGestora() : boolean {
    return this.modoVisualizarTelaUnidadeGestora;
  }

  setIdUniversoAuditavel(id: number){
    this.universoAuditavelId = id;
  }

  getIdUniversoAuditavel(): number{
    return this.universoAuditavelId;
  }

  setIdTipoUniversoAuditavel(id: number){
    this.tipoUniversoAuditavelId = id;
  }

  getIdTipoUniversoAuditavel(): number{
    return  this.tipoUniversoAuditavelId ;
  }
}
