<div id="modal-content-a-equipe">
    <div class="card card-secondary card-outline" cdkDragBoundary="html" cdkDrag cdkDragRootElement=".cdk-overlay-pane">
        <div class="card-header bg-light" cdkDragHandle>
            <h4 class="card-title mb-0">{{titulo}}</h4>
            <div class="card-tools">
                <button type="button" class="btn btn-tool" (click)="cancelar()">
                    <i class="fas fa-times"></i>
                </button>
            </div>
        </div>
        <div class="card-body p-0">
            <div class="table-container p-3">
                <div class="row">
                    <div class="form-group col-md-12 was-validated"
                        [ngClass]="{ 'was-validated': campoObrigatorio === true }">
                        <label for="inputNome" class="mb-1">Nome</label>
                        <input required class="form-control form-control-sm" type="text" id="inputNome"
                            [(ngModel)]="model.nome" [attr.disabled]="visualizar ? true : null" />
                        <div class="invalid-feedback">
                            Campo obrigatório
                        </div>
                    </div>
                </div>
                <div class="card" *ngIf="atualizando">
                    <div style="background-color: #f1f1f1;">
                        <h6 style="padding-left: 10px; padding-top: 5px; font-weight: bold;">Níveis</h6>
                    </div>
                    <app-form-magnitude-escala (updateData)="updateData()" [visualizar]="visualizar">
                    </app-form-magnitude-escala>
                    <app-grid-magnitude-escala (updatedData)="setListData($event)" (viewData)="viewData($event)" [visualizar]="visualizar">
                    </app-grid-magnitude-escala>
                </div>
            </div>
            <div class="card-footer">
                <button type="button" class="btn btn-primary btn-sm mr-1" (click)="concluir()" [hidden]="visualizar">
                    <i class="fas fa-check"></i> {{txtBtnConcluir}}
                </button>

                <button type="button" class="btn btn-default btn-sm" (click)="cancelar()">
                    <i class="fas fa-times"></i> Cancelar
                </button>
            </div>
        </div>
    </div>
</div>