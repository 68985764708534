import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { EntendendoEntidadeService } from "../../../../services/entendendo-entidade.service";
import { SharedService } from "../../../../services/shared.service";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";

@Component({
  selector: 'app-modal-solicitar-preenchimento',
  templateUrl: './modal-solicitar-preenchimento.component.html',
  styleUrls: ['./modal-solicitar-preenchimento.component.scss']
})
export class ModalSolicitarPreenchimentoUniversoComponent implements OnInit{

  prazoDias: number;
  UniversoAuditavelId: number;

  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private universoAuditavelService: UniversoAuditavelService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ModalSolicitarPreenchimentoUniversoComponent>

  ) { }

  ngOnInit(): void {}
  public cancelar(){
    this.dialogRef.close();
  }

  public concluir(){
    this.universoAuditavelService
      .solicitarPreenchimentoQuestionario(this.data.param1, this.prazoDias)
      .then((res) => {
        if (res.sucesso) {
          // aqui chamar o pagina principal
          this.dialogRef.close();
        } else {
          // this.exibirMensagemAlerta(res.mensagem.descricao);
        }
      })
      .catch((err) => {
        // this.exibirMensagemErro(err.mensagem);
      })
      .finally(() => {
        //  this.buscar();
      });
  }

  public onChangePreencherPrazo(event){
    this.prazoDias = event.target.value;
  }

}
