import { Component, Inject, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { QuestionarioUGService } from "../../../../services/questionarioug.service";
import { SharedService } from "../../../../services/shared.service";
import { UniversoAuditavelService } from "../../../../services/universo-auditavel.service";


@Component({
  selector: 'app-modal-universo-auditavel-importa-dados',
  templateUrl: './modal-universo-auditavel-importa-dados.component.html',
  styleUrls: ['./modal-universo-auditavel-importa-dados.component.scss']
})
export class ModalUniversoAuditavelImportaDadosComponent implements OnInit{
  unidadeGestoraId: number;
  constructor(
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any ,
    private universoAuditavelService: UniversoAuditavelService,
    private sharedService: SharedService,
    private dialogRef: MatDialogRef<ModalUniversoAuditavelImportaDadosComponent>
  ) { }
  ngOnInit(): void {
    this.unidadeGestoraId = this.sharedService.getId();
  }
  public Nao() {
    this.dialogRef.close();
    this.router.navigate(['gerar-questionario-shared']);
  }
  public Sim(){

    if(!this.data.tipoUniversoId){
      this.dialogRef.close();
    }

    this.universoAuditavelService.inserirUniversoAPartirDoUltimo(this.data.unidadeGestoraId, this.data.tipoUniversoId).then(res=>{
      if (res.sucesso) {
        this.dialogRef.close();
        this.router.navigateByUrl(`/unidades-gestoras/${this.unidadeGestoraId}?aba=3`);
      }
    })

  }
  public cancelar() {
    this.dialogRef.close();
  }
}
