<div class="mat-elevation-z1">
    <!-- <table mat-table [dataSource]="dataSource" class="w-100" matSort matSortDisableClear class="mat-elevation-z8"
        #tabela1="matSort" [hidden]="semDados">
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <ng-container matColumnDef="avaliacao">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Valor</th>
            <td mat-cell *matCellDef="let element">{{element.valor}}</td>
        </ng-container>
        <ng-container matColumnDef="valorDoRisco">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Magnitude</th>
            <td mat-cell *matCellDef="let element">{{getMagnitudeName(element.magnitude)}}</td>
        </ng-container>
        <ng-container cdkColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="
                    let element;
                    let i = index
                " class="block text-right">
                <div class="btn-group">
                    <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                        (click)="excluir(element)">
                        <i class="far fa-trash-alt"></i>
                    </button>
                </div>
            </td>
        </ng-container>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="clickable" [ngClass]="'bg-light'">
        </tr>
    </table>
    <h5 [hidden]="!semDados" class="table-no-data">
        Nenhum tipo de trabalho
        cadastrado.
    </h5> -->
    <table class="table table-condensed" cdkDropList (cdkDropListDropped)="onDrop($event)">
        <thead>
            <tr>
                <th class="col-md-6">Valor</th>
                <th class="col-md-2">Magnitude</th>
                <th class="col-md-3 text-center">Ações</th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let element of listData; let i = index">
                <tr cdkDrag [cdkDragData]="element" cdkDragLockAxis="y">
                    <td>{{ element?.valor }}</td>
                    <td>{{element.magnitude}}</td>
                    <td class="text-center" style="text-align: center; vertical-align: middle; padding: 10px">
                        <div class="btn-group mr-1">
                            <div class="d-flex align-items-center mr-2">
                                <button [hidden]="visualizar" type="button" class="btn btn-danger btn-sm text-left"
                                    (click)="excluir(element)">
                                    <i class="far fa-trash-alt"></i>
                                </button>
                                <button type="button" class="btn btn-info btn-sm mr-1"
                                (click)="onViewData(element)">
                                <i class="far fas fa-eye"></i>
                            </button>
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
    <ng-template #dragHandleTmpl>
        <svg class="bi bi-list handle" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor"
            xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                clip-rule="evenodd" />
        </svg>
    </ng-template>
</div>