import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IControle } from 'src/app/models/controle.model';
import { ControleService } from 'src/app/services/controle.service';
import { ModalControleComponent } from './modal-controle/modal-controle.component';
import Swal from 'sweetalert2';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from "@angular/cdk/drag-drop";

@Component({
  selector: 'app-criterios-avaliacao-form-controles',
  templateUrl: './criterios-avaliacao-form-controles.component.html',
  styleUrls: ['./criterios-avaliacao-form-controles.component.scss']
})
export class CriteriosAvaliacaoFormControlesComponent implements OnInit {

  criteriosAvaliacaoRiscosId: any;
  visualizar = false;
  dataSource = new MatTableDataSource<any>([]);
  selectedModel: any;

  listRisco : any[]=[]

  displayedColumns: string[] = [
    "avaliacao",
    "valorDoRisco",
    "actions",
  ];

  searchNome: string;
  searchAtivo = true;
  semDados = true;
  constructor(
    public matDialog: MatDialog,
    private controleService: ControleService
  ) { }

  ngOnInit(): void { }

  salvarDados(e: any) {
    console.log(e);
  }

  adicionar() { }

  excluirControle(e: any) {
    Swal.fire({
      title: "Excluir Controle",
      text: "Tem certeza que deseja excluir esse controle?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.value) {
        this.controleService.excluir(e.id).then(() => {
          this.getControleByCriterios()
        })
        return;
      }
      return;
    });
  }

  setCriteriosAvaliacaoRiscosId(criteriosAvaliacaoRiscosId: number) {
    this.criteriosAvaliacaoRiscosId = criteriosAvaliacaoRiscosId
    this.getControleByCriterios()
  }

  getControleByCriterios() {
    this.controleService.obter(this.criteriosAvaliacaoRiscosId, true, true).then((res) => {
      // this.dataSource = new MatTableDataSource(res.dados);
      // this.dataSource._updateChangeSubscription();
      this.listRisco = res.dados
      this.semDados = this.dataSource.filteredData.length === 0;
    })
  }

  abrirModalControle(element?: IControle) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = "modal-component";
    dialogConfig.width = "650px";
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      id: element ? element.id : 0,
      avaliacao: element?.avaliacao,
      valorDoRisco: element?.valorDoRisco,
      situacaoControle: element?.situacaoControle,
      nivelDaConfianca: element?.nivelDaConfianca,
      criteriosAvaliacaoRiscosId: this.criteriosAvaliacaoRiscosId,
      ativo: element?.ativo,
      listData : this.listRisco
    };
    const modal = this.matDialog.open(
      ModalControleComponent,
      dialogConfig
    );
    modal.afterClosed().subscribe((sucesso) => {
      if (sucesso) {
        this.getControleByCriterios();
      }
    });
  }

    onDrop(event: CdkDragDrop<string[]>) {
      if (event.previousContainer === event.container) {
        moveItemInArray(this.listRisco, event.previousIndex, event.currentIndex);
      }
  
      this.listRisco.forEach((element, idx) => {
        element.ordem = (idx + 1).toString();
        this.controleService.atualizar(element)
      })
  
    }
}
