import { BaseService } from "./base.service";
import { HttpClient } from "@angular/common/http";
import { LocationStrategy } from "@angular/common";
import {
  IUniversoAuditavelItemRespostaItensModel,
  IUniversoAuditavelItemRespostaModel,
  IUniversoAuditavelModel
} from "../models/universo-auditavel.model";
import { IBaseModel } from "../models/base.model";
import { Injectable } from "@angular/core";
import { IListaQuestionarioRespostaUGModel } from "../models/questionario-ug.model";

@Injectable({
  providedIn: 'root',
})
export class UniversoAuditavelItemRespostaService extends BaseService{

  constructor(httpClient: HttpClient, locationStrategy: LocationStrategy) {
    super(httpClient, locationStrategy);
  }

  public async inserir(data: IUniversoAuditavelItemRespostaModel[]): Promise<IBaseModel<IUniversoAuditavelItemRespostaModel[]>> {
    // Logando os dados que serão enviados
    console.log('Dados enviados para inserção:', data);

    return this.httpClient
      .post<IBaseModel<IUniversoAuditavelItemRespostaModel[]>>(`${this.apiBaseUrl}/UniversoAuditavelItemResposta/inserir`, data)
      .toPromise();
  }

  public async ObterRespostaPorCriterio(idCriterio: number, idUG: number, idTipoUNiverso: number): Promise<IBaseModel<IUniversoAuditavelItemRespostaModel[]>>{
    return this.httpClient
      .get<IBaseModel<IUniversoAuditavelItemRespostaModel[]>>(`${this.apiBaseUrl}/UniversoAuditavelItemResposta/resposta/${idCriterio}/${idUG}/${idTipoUNiverso}`)
      .toPromise();
  }

  public async ObterRespostaPorCriterios(idUG: number, idTipoUNiverso: number): Promise<IBaseModel<IUniversoAuditavelItemRespostaItensModel>>{
    return this.httpClient
      .get<IBaseModel<IUniversoAuditavelItemRespostaItensModel>>(`${this.apiBaseUrl}/UniversoAuditavelItemResposta/respostasProCriterios/${idTipoUNiverso}/${idUG}`)
      .toPromise();
  }
}
