<div class="table-container p-2">
    <div class="row">
        <div class="form-group col-md-4 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputNome" class="mb-1">Valor</label>
            <input required class="form-control form-control-sm" type="text" id="inputNome" [(ngModel)]="model.valor"
                (ngModelChange)="existsItem = false" [attr.disabled]="visualizar ? true : null" />
            <div class="invalid-feedback">
                Campo obrigatório
            </div>
        </div>
        <div class="form-group col-md-8 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputOperador_1" class="mb-1">Magnitude</label>
            <input required class="form-control form-control-sm" type="text" id="inputNome"
                [(ngModel)]="model.magnitude" (ngModelChange)="existsItem = false"
                [attr.disabled]="visualizar ? true : null" />
        </div>
    </div>
    <div class="row">
        <div class="form-group col-md-12 was-validated" [ngClass]="{ 'was-validated': campoObrigatorio === true }">
            <label for="inputNome" class="mb-1">Descrição</label>
            <textarea required class="form-control form-control-sm" type="text" id="inputNome" rows="3"
                [attr.disabled]="visualizar ? true : null" [(ngModel)]="model.descricao">
                </textarea>
            <div class="invalid-feedback">
                Campo obrigatório
            </div>
        </div>
    </div>
    <div style="display: flex; justify-content: space-between;">
        <div>
            <span *ngIf="existsItem" style="color: red">Escala de Impacto já adicionada! Informe outro valor para
                prosseguir</span>
        </div>
        <div>
            <button type="button" class="btn btn-success btn-sm mr-1" (click)="concluir()" [hidden]="visualizar">
                <i class="far fa-add"></i>
                Adicionar
            </button>
        </div>
    </div>
</div>